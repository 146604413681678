import React, { FC } from 'react';

import './index.scss';

const TableBody: FC = ({ children }) => (
  <tbody className='tbody-default'>
    {children}
  </tbody>
);

export default TableBody;
