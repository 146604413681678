import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import LogoDesktop from '../../assets/svg/logo.svg';
import LogoMobile from '../../assets/svg/si14_logo_m.svg';
import { selectUserIsAuth } from '../../store/profile/selectors';
import { AccountInfo } from './AccountInfo';
import { Deposit } from './Deposit';
import { LangSelect } from './LangSelect';
import { TimeZone } from './TimeZone';

import './index.scss';

export const Header: React.FC = () => {
  const isAuth = useSelector(selectUserIsAuth);

  if (!isAuth) {
    return null;
  }

  return (
    <header className="header">
      <div className="header__left">
        <div className="header__logo-desktop">
          <Link className="header__logo-link" to="/">
            <img alt="desktop-logo" src={LogoDesktop} />
          </Link>
        </div>
        <div className="header__logo-mobile">
          <Link className="header__logo-link" to="/">
            <img alt="mobile-logo" src={LogoMobile} />
          </Link>
        </div>
      </div>

      <div className="overlay" />

      <div className="header__right">
        <Deposit />
        <AccountInfo />
        <TimeZone />
        <LangSelect />
      </div>
    </header>
  );
};
