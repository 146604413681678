import { createSelector } from '@reduxjs/toolkit';

import { getStringFullDateWithoutTime, getStringFullDateWithTime } from '../../utils/converters';
import { RootState } from '../rootReducer';

const selectUserState = (state: RootState) => state.user;
const selectAllState = (state: RootState) => state;

export const selectUsername = createSelector(selectUserState, (user) => user.login);

export const selectUserInfo = createSelector(selectAllState, (state) => ({
  id: state.user.id,
  phoneNumber: state.user.phoneNumber,
  clicks: state.user.clicks,
  agreementFrom: state.user.agreementFrom,
  registrationDate: state.user.registrationDate ?
    getStringFullDateWithoutTime(state.user.registrationDate, state.profile.timeZone)
    : '',
  email: state.user.login,
  lastPayout: state.user.lastOperation ? getStringFullDateWithTime(state.user.lastOperation, state.profile.timeZone) : '-',
}));

export const selectUserDepositInfo = createSelector(selectUserState, (user) => ({
  balance: user.amount,
  users: user.referalsCount,
}));

export const selectUserReferralClick = createSelector(selectUserState, (user) => user.clicks);

export const selectUserReferralLink = createSelector(selectUserState, (user) => user.partnerLink);

export const selectUserPartnershipInfo = createSelector(selectUserState, ({ partnerShip, locations }) => ({
  ...partnerShip,
  locations,
}));
