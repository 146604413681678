import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { Main } from './components/Main';

export const App: React.FC = () => (
  <>
    <Header />
    <Main />
    <Footer />
  </>
);
