import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ReactComponent as ArrowLeft } from '../../../assets/svg/arrow_left--mono.svg';
import UserIcon from '../../../assets/svg/ic_login_1.svg';
import { logout } from '../../../store/profile/actions';
import { selectUserInfo } from '../../../store/user/selectors';

import '../AuthButton/AuthButton.scss';

const Desktop: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const listRef = React.useRef<HTMLUListElement>(null);
  const iconRef = React.useRef<HTMLDivElement>(null);
  const userInfo = useSelector(selectUserInfo);

  const handleClickOutside = (e: Event) => {
    if (
      !listRef.current?.contains(e.target as Node) &&
      !iconRef.current?.contains(e.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="header__auth-controls" onClick={() => setIsOpen(!isOpen)}>
      <a className="login_link">
        <span className="ic_login" ref={iconRef}>
          <img alt="login" src={UserIcon} />
        </span>
        <span className="name_login">{userInfo.email}</span>
      </a>

      {isOpen && (
        <ul className="intl__dropdown dropdown--base" ref={listRef}>
          <li>
            <ul className="user-info">
              <Link className="user-info__item user-info__link" to="/">
                <div className="title">ID:</div>
                <div className="info">{userInfo.id}</div>
                <ArrowLeft className="icon" />
              </Link>
              <li className="user-info__item">
                <div className="title">
                  {t('components.header.phoneNumber')}
                  :
                </div>
                <div className="info">{userInfo.phoneNumber}</div>
              </li>
              <li className="user-info__item">
                <div className="title">
                  {t('components.header.agreementFrom')}
                  :
                </div>
                <div className="info">{userInfo.agreementFrom}</div>
              </li>
              <li className="user-info__item">
                <div className="title">
                  {t('components.header.registrationDate')}
                  :
                </div>
                <div className="info">{userInfo.registrationDate}</div>
              </li>
              <li className="user-info__item">
                <div className="title">{t('components.header.email')}</div>
                <div className="info">{userInfo.email}</div>
              </li>
              <li className="user-info__item">
                <div className="title">{t('components.header.lastPayout')}</div>
                <div className="info">{userInfo.lastPayout}</div>
              </li>
            </ul>
          </li>
          <li className="dropdown--item-style" onClick={() => dispatch(logout())}>
            {t('components.header.exit')}
          </li>
        </ul>
      )}
    </div>
  );
};

export const AccountInfo: React.FC = () => (
  <div className="header__auth">
    <Desktop />

    <div className="header__auth-mobile">
      <img alt="user" className="user-image-avatar" src={UserIcon} />
    </div>
  </div>
);
