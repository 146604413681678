import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './index.scss';

export const Success: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="form card registration-success">
      <h2 className="form__title registration-success__title">
        {t('authPage.registerForm.registrationSuccessful')}
      </h2>
      <p className="form__text registration-success__text">
        {t('authPage.registerForm.checkYourEmail')}
      </p>
      <Link className="email-confirmation-success__link" to="/">
        {t('authPage.registerForm.returnToLogin')}
        &rarr;
      </Link>
    </div>
  );
};
