import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Filter } from '../../components/generic/Filter';
import { Pagination } from '../../components/Pagination';
import { fetchCardOperations, setCardOperationsFilter, setCardOperationsPageNumber } from '../../store/cards/actions';
import {
  selectCardOperations,
  selectCardOperationsFilter,
  selectCardOperationsMeta,
  selectCardOperationsPageNumber,
} from '../../store/cards/selectors';
import { selectCardOperationsIsLoading } from '../../store/loads/selectors';
import { Table } from './Table';

import './index.scss';

interface PageParams {
  id: string;
}

export const WithdrawalHistoryPage: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<PageParams>();
  const dispatch = useDispatch();
  const isLoading = useSelector(selectCardOperationsIsLoading);
  const operations = useSelector(selectCardOperations);
  const meta = useSelector(selectCardOperationsMeta);
  const filterValue = useSelector(selectCardOperationsFilter);
  const currentPage = useSelector(selectCardOperationsPageNumber);

  React.useEffect(() => {
    if (filterValue) {
      dispatch(fetchCardOperations({ id: Number(id), limit: 50, page: currentPage, ...filterValue }));
    } else {
      dispatch(fetchCardOperations({ id: Number(id), limit: 50, page: currentPage }));
    }
  }, [filterValue, currentPage]);

  React.useEffect(() => () => {
    dispatch(setCardOperationsFilter(null));
  }, []);

  return (
    <div className="withdrawal-history-page">
      <h1 className="withdrawal-history-page__title">{t('withdrawalHistoryPage.title')}</h1>
      <Filter disabled={isLoading} onChange={(value) => dispatch(setCardOperationsFilter(value))} type="statistics" />
      <div className="table-wrapper">
        <Table data={operations} />
        {meta && meta.total > meta.perPage && (
          <Pagination
            active={meta.currentPage}
            disableGutterBottom
            onChangePage={(value) => dispatch(setCardOperationsPageNumber(value))}
            pages={new Array<number>(meta.lastPage).fill(0)?.map((_, i) => i + 1)}
          />
        )}
      </div>
    </div>
  );
};
