import React, { FC } from 'react';

import './index.scss';

const TableRow: FC = ({ children }) => (
  <tr className='tr-default'>
    {children}
  </tr>
);

export default TableRow;
