import { Preloader } from '../../generic/Preloader/Preloader';

import './index.scss';

interface WithPreloaderProps {
  isLoading?: boolean;
}

export const withPreloader =
  <T extends WithPreloaderProps>(Component: React.ComponentType<T>): React.FC<WithPreloaderProps & T> =>
    ({ isLoading, ...props }) => (
      <div className="with_preloader">
        <Component {...props as T} />
        {isLoading && <Preloader />}
      </div>
    );
