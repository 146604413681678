import i18next from 'i18next';
import * as yup from 'yup';

const name = /^[a-zA-Z]+$/;
const password = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm;
const cardholder = /^([a-zA-Z]+)+\s+([a-zA-Z]+)$/;
const expiryDate = /^(0[1-9]|1[0-2])\/([0-9]{4})$/;

const messages = {
  nameRequired: () => i18next.t('errors.name'),
  validEmail: () => i18next.t('errors.email'),
  validPhone: () => i18next.t('errors.phone'),
  validPassword: () => i18next.t('errors.passwordCharacters'),
  passwordMatches: () => i18next.t('errors.passwordMatches'),
  validCard: () => i18next.t('errors.card'),
  validExpiryDate: () => i18next.t('errors.expiryDate'),
};

const getNameRules = (rgxp: RegExp) => yup
  .string()
  .matches(rgxp, messages.nameRequired)
  .max(35, () => i18next.t('errors.long'))
  .required(messages.nameRequired);

const getPhoneNumberRules = () => yup
  .string()
  .min(11, messages.validPhone)
  .required(messages.validPhone);

export const registerSchema = yup.object().shape({
  firstName: getNameRules(name),
  lastName: getNameRules(name),
  phoneNumber: getPhoneNumberRules(),
  email: yup.string().email(messages.validEmail).required(messages.validEmail),
  password: yup.string()
    .matches(password, messages.validPassword)
    .required(() => i18next.t('errors.password')),
  confirmPassword: yup.string().oneOf([yup.ref('password'), null], messages.passwordMatches),
});

export const passwordRecoverySchema = yup.object().shape({
  email: yup.string().email(messages.validEmail).required(messages.validEmail),
});

export const setNewPasswordSchema = yup.object().shape({
  email: yup.string(),
  password: yup.string()
    .matches(password, messages.validPassword)
    .required(() => i18next.t('errors.password')),
  passwordConfirmation: yup.string().oneOf([yup.ref('password')], messages.passwordMatches),
});

export const authSchema = yup.object().shape({
  username: yup.string().email(() => i18next.t('errors.notValidEmail')).required(() => i18next.t('errors.notValidEmail')),
  password: yup.string().required(() => i18next.t('errors.notValidPassword')),
});

export const cardSchema = yup.object().shape({
  cardholder: getNameRules(cardholder),
  number: yup.string()
    .length(19, messages.validCard)
    .required(messages.validCard),
  expiryDate: yup.string()
    .matches(expiryDate, messages.validExpiryDate)
    .required(messages.validExpiryDate),
});
