import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  connect,
  useDispatch,
  useSelector,
} from 'react-redux';

import { compose } from '@reduxjs/toolkit';

import SectionWithBody from '../../../../components/generic/Section/SectionWithBody';
import SectionWithHeader from '../../../../components/generic/Section/SectionWithHeader';
import { withErrorMessage } from '../../../../components/HOC/withErrorMessage';
import { withPreloader } from '../../../../components/HOC/withPreloader';
import {
  selectWithdrawalTransactionsErrorMessage,
  selectWithdrawalTransactionsIsLoading,
} from '../../../../store/loads/selectors';
import { RootState } from '../../../../store/rootReducer';
import { fetchWithdrawalTransactions } from '../../../../store/withdrawals/action';
import { selectWithdrawalTransactions } from '../../../../store/withdrawals/selectors';
import TableWithdrawals from './TableWithdrawals';

import './index.scss';

interface IPendingWithdrawals {
  paymentMethod: string;
}

const PendingWithdrawalsComponent: FC<IPendingWithdrawals> = ({ paymentMethod }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const transactions = useSelector(selectWithdrawalTransactions);
  useEffect(() => {
    dispatch(fetchWithdrawalTransactions({ page: 1, limit: 4 }));
  }, []);

  return (
    <>
      <SectionWithHeader isHelperTitle title={t('accountWithdrawPage.alternativeTab.pendingWithdrawls.title')} />
      {transactions?.operations ? (
        <TableWithdrawals meta={transactions.meta} operations={transactions.operations} />
      ) : (
        <SectionWithBody
          // eslint-disable-next-line max-len
          description={t('accountWithdrawPage.alternativeTab.pendingWithdrawls.description')}
          isHelperDescription={false}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoading: selectWithdrawalTransactionsIsLoading(state),
  error: selectWithdrawalTransactionsErrorMessage(state),
});

const PendingWithdrawals = compose<FC<IPendingWithdrawals>>(
  connect(mapStateToProps),
  withErrorMessage,
  withPreloader,
)(PendingWithdrawalsComponent);

export default PendingWithdrawals;
