import React, { FC } from 'react';

import {
  ConfirmModal,
  ConfirmModalAction,
  ConfirmModalBody,
  ConfirmModalHeader,
} from '../../../../components/generic/ConfirmModal';

interface IModal {
  handleClose: () => void;
  isOpen: boolean;
  handleConfirm: () => void;
  title: string;
}

const Modal: FC<IModal> = ({
  handleClose,
  isOpen,
  handleConfirm,
  title,
  children,
}) => (
  <ConfirmModal handleClose={handleClose} isVisible={isOpen}>
    <ConfirmModalHeader title={title} />
    <ConfirmModalBody>
      {children}
    </ConfirmModalBody>
    <ConfirmModalAction handleClose={handleClose} handleConfirm={handleConfirm} />
  </ConfirmModal>
);

export default Modal;
