import React from 'react';
import { useTranslation } from 'react-i18next';

import CamblingLogo from '../../assets/img/logo/cambling.png';
import FUNLogo from '../../assets/img/logo/fun.png';
import GAMLogo from '../../assets/img/logo/gam.png';
import GTLogo from '../../assets/img/logo/gt.png';
import MGALogo from '../../assets/img/logo/mga.png';
import AgeRestrictedLogo from '../../assets/svg/18.svg';

import './index.scss';

export const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="footer__copyright">{t('components.footer.copyright')}</div>
      <div className="footer__icons">
        <img
          alt="cambling"
          height={22}
          src={CamblingLogo}
          width={90}
        />
        <img
          alt="MGA"
          height={22}
          src={MGALogo}
          width={110}
        />
        <img
          alt="GT"
          height={22}
          src={GTLogo}
          width={26}
        />
        <img
          alt="GAM"
          height={22}
          src={GAMLogo}
          width={22}
        />
        <img
          alt="FUN"
          height={22}
          src={FUNLogo}
          width={123}
        />
        <img
          alt="age restricted"
          className="big-img"
          height={30}
          src={AgeRestrictedLogo}
          width={30}
        />
      </div>
      <div className="footer__content">
        <p>
          {t('components.footer.warning')}
        </p>
      </div>
    </footer>
  );
};
