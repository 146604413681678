export const endpoints = {
  auth: {
    token(): string {
      return '/realms/Si14Partner/protocol/openid-connect/token';
    },
    registration(): string {
      return '/registration';
    },
    sendPasswordRecoveryMail(): string {
      return '/password-recovery';
    },
    setNewPassword(): string {
      return '/password-recovery/newpassword';
    },
  },
  main: {
    user(): string {
      return '/user';
    },
    referrals(): string {
      return '/registrations';
    },
    operations(): string {
      return '/operations';
    },
    cards(): string {
      return '/user_cards';
    },
    cardOperations(): string {
      return '/card';
    },
  },
};
