import { from, of } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';

import { EpicFunc } from '../../@types/Epic.interface';
import API from '../../api';
import { fetchUserData, fetchUserDataFailed, fetchUserDataSuccess } from './actions';

export const userEpic: EpicFunc = (actions$) =>
  actions$.pipe(
    filter(fetchUserData.match),
    mergeMap(() =>
      from(API.user.fetchUserData())
        .pipe(
          mergeMap(({ data }) =>
            of(fetchUserDataSuccess(data)),
          ),
          catchError((error) =>
            of(fetchUserDataFailed(error.message)),
          ),
        ),
    ),
  );
