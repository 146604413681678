/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { yupResolver } from '@hookform/resolvers/yup';

import { setNewPassword } from '../../../store/profile/actions';
import { setNewPasswordSchema } from '../../../utils/validationSchemas';

interface FormValues {
  email: string;
  password: string;
  passwordConfirmation: string;
}

export const SetNewPassword: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const emailFromStorage = localStorage.getItem('recoveryEmail') || '';
  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(setNewPasswordSchema),
  });

  const onSubmit: SubmitHandler<FormValues> = ({ password, email }) => {
    const confirmEmail = email || emailFromStorage;
    dispatch(setNewPassword({ email: confirmEmail, password }));
  };

  useEffect(() => {
    if (emailFromStorage) {
      setValue('email', emailFromStorage);
    }
  }, []);

  return (
    <div className="auth-page">
      <div className="auth-page__form-wrapper">
        <form className="form password-recovery-form card" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="form__title">{t('authPage.setNewPassword.title')}</h2>

          <div className="form__group">
            <label className="form__label">{t('authPage.setNewPassword.email')}</label>

            <input
              aria-invalid={errors.email ? 'true' : 'false'}
              className="input"
              disabled={!!emailFromStorage}
              required
              type='email'
              {...register('email')}
            />

            {errors.email && <span className="error" role="alert">{errors.email.message}</span>}
          </div>

          <div className="form__group">
            <label className="form__label">{t('authPage.setNewPassword.password')}</label>

            <input
              aria-invalid={errors.password ? 'true' : 'false'}
              className="input"
              type="password"
              {...register('password')}
            />

            {errors.password && <span className="error" role="alert">{errors.password.message}</span>}
          </div>

          <div className="form__group">
            <label className="form__label">{t('authPage.setNewPassword.confirmPassword')}</label>

            <input
              aria-invalid={errors.password ? 'true' : 'false'}
              className="input"
              type="password"
              {...register('passwordConfirmation')}
            />

            {errors.passwordConfirmation && <span className="error" role="alert">{errors.passwordConfirmation.message}</span>}
          </div>

          <div className="form__footer">
            <div className="form__actions">
              <button className="button button--primary" type="submit">{t('authPage.setNewPassword.confirm')}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
