import { createReducer } from '@reduxjs/toolkit';

import { PageFilter, PageMeta } from '../../@types/Common.interface';
import { Operation } from '../../@types/Operation.interface';
import { setDiagramOperations, setOperations, setOperationsFilter, setOperationsMeta, setOperationsPageNumber } from './actions';

export interface OperationsState {
  items: Operation[],
  diagramItems: Operation[],
  meta: null | PageMeta,
  filter: null | PageFilter;
  currentPage: number;
}

const initialState: OperationsState = {
  items: [],
  diagramItems: [],
  meta: null,
  filter: null,
  currentPage: 1,
};

export const operationsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setOperations, (state, action) => {
      // state.items = action.payload;
    })
    .addCase(setDiagramOperations, (state, action) => {
      // state.diagramItems = action.payload;
    })
    .addCase(setOperationsMeta, (state, action) => {
      // state.meta = action.payload;
    })
    .addCase(setOperationsFilter, (state, action) => {
      // state.filter = action.payload;
      state.currentPage = 1;
    })
    .addCase(setOperationsPageNumber, (state, action) => {
      // state.currentPage = action.payload;
    });
});
