import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

import translationCH from '../locales/ch/translation.json';
import translationDE from '../locales/de/translation.json';
import translationDK from '../locales/dk/translation.json';
import translationEN from '../locales/en/translation.json';
import translationES from '../locales/es/translation.json';
import translationFR from '../locales/fr/translation.json';
import translationGR from '../locales/gr/translation.json';
import translationIT from '../locales/it/translation.json';
import translationPT from '../locales/pt/translation.json';
import translationRU from '../locales/ru/translation.json';
import translationSE from '../locales/se/translation.json';
import translationTR from '../locales/tr/translation.json';

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  de: {
    translation: translationDE,
  },
  dk: {
    translation: translationDK,
  },
  es: {
    translation: translationES,
  },
  gr: {
    translation: translationGR,
  },
  it: {
    translation: translationIT,
  },
  pt: {
    translation: translationPT,
  },
  ru: {
    translation: translationRU,
  },
  se: {
    translation: translationSE,
  },
  ch: {
    translation: translationCH,
  },
  tr: {
    translation: translationTR,
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    debug: false,
    detection: {
	  order: ['localStorage', 'cookie'],
	  caches: ['localStorage', 'cookie'],
    },
    fallbackLng: 'en',
    interpolation: {
	  escapeValue: false,
    },
  });
