import { createReducer } from '@reduxjs/toolkit';

import { PageFilter, PageMeta } from '../../@types/Common.interface';
import { Operation } from '../../@types/Operation.interface';
import { Card } from '../../@types/Profile.interface';
import {
  fetchCardListSuccess,
  fetchCardOperationsSuccess,
  setCardOperationsFilter,
  setCardOperationsMeta,
  setCardOperationsPageNumber,
} from './actions';

export interface CardsState {
  items: Card[];
  operations: {
    items: Operation[];
    meta: null | PageMeta;
    filter: null | PageFilter;
    currentPage: number;
  };
}

const initialState: CardsState = {
  items: [],
  operations: {
    items: [],
    meta: null,
    filter: null,
    currentPage: 1,
  },
};

export const cardsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchCardListSuccess, (state, action) => {
      // state.items = action.payload;
    })
    .addCase(fetchCardOperationsSuccess, (state, action) => {
      // state.operations.items = action.payload;
    })
    .addCase(setCardOperationsMeta, (state, action) => {
      // state.operations.meta = action.payload;
    })
    .addCase(setCardOperationsFilter, (state, action) => {
      // state.operations.filter = action.payload;
      state.operations.currentPage = 1;
    })
    .addCase(setCardOperationsPageNumber, (state, action) => {
      // state.operations.currentPage = action.payload;
    });
});
