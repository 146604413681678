import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import SectionWithBody from '../../../../components/generic/Section/SectionWithBody';
import SectionWithHeader from '../../../../components/generic/Section/SectionWithHeader';
import { IWithdrawal, TWithdrawal } from '../index';
import CardContainer from './CardContainer';
import CollectMoney from './CollectMoney';
import WithdrawalMoney from './WithdrawalMoney';

import './index.scss';

interface IEnterWithdrawAmount {
  changeWithdrawalValues: (value: string, type: TWithdrawal) => void;
  values: IWithdrawal;
  errors: IWithdrawal;
  setErrors: (value: string, type: TWithdrawal) => void;
  isSubmit: number;
  commission: number;
}

const EnterWithdrawAmount: FC<IEnterWithdrawAmount> = (
  {
    values,
    changeWithdrawalValues,
    errors,
    setErrors,
    isSubmit,
    commission,
  },
) => {
  const { t } = useTranslation();
  const isShowDefault = values.paymentMethod === 'default';
  return (
    <>
      <SectionWithHeader isHelperTitle title={t('accountWithdrawPage.alternativeTab.enterWithdrawAmount.title')} />
      {isShowDefault ? (
        <SectionWithBody
          description={t('accountWithdrawPage.alternativeTab.enterWithdrawAmount.description')}
          isHelperDescription={false}
        />
      ) : (
        <>
          <CollectMoney
            amount={values.amount}
            changeWithdrawalValues={changeWithdrawalValues}
            errors={errors}
            isSubmit={isSubmit}
            setErrors={setErrors}
          />
          <CardContainer
            changeWithdrawalValues={changeWithdrawalValues}
            errors={errors}
            values={values}
          />
          <WithdrawalMoney amount={values.amount} commission={commission} error={errors.paymentMethod} />
        </>
      )}
    </>
  );
};

export default EnterWithdrawAmount;
