import { from, merge, of } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';

import { EpicFunc } from '../../@types/Epic.interface';
import API from '../../api';
import { mapOperation } from '../../api/map';
import { compareOperationsByDate } from '../../utils/utils';
import {
  fetchOperations,
  fetchOperationsFailed,
  fetchOperationsSuccess,
  setDiagramOperations,
  setOperations,
  setOperationsMeta,
} from './actions';

export const operationEpic: EpicFunc = (actions$) =>
  actions$.pipe(
    filter(fetchOperations.match),
    mergeMap((action$) =>
      from(API.fetchUserOperations(action$.payload))
        .pipe(
          mergeMap(({ data }) =>
            merge(
              of(setOperationsMeta(data.meta)),
              of(fetchOperationsSuccess()),
              of(setOperations(data.operations?.map(mapOperation))),
              of(setDiagramOperations(data.diagramOperations?.map(mapOperation).sort(compareOperationsByDate))),
            ),
          ),
          catchError((error) =>
            of(fetchOperationsFailed(error.message)),
          ),
        ),
    ),
  );
