import React, { FC } from 'react';

import './table.scss';

interface ITable {
  className?: string;
}

const Table: FC<ITable> = ({ children, className }) => (
  <table className={className ? `table-default ${className}` : 'table-default'}>
    {children}
  </table>
);

export default Table;
