import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import SectionWithBody from '../../../../components/generic/Section/SectionWithBody';
import SectionWithHeader from '../../../../components/generic/Section/SectionWithHeader';

import './index.scss';

interface IPaymentInformation {
  paymentMethod: string;
}

const PaymentInformation: FC<IPaymentInformation> = ({ paymentMethod }) => {
  const isShowInstruction = paymentMethod === 'default';
  const { t } = useTranslation();

  return (
    <div className='payment-information'>
      <SectionWithHeader isHelperTitle title={t('accountWithdrawPage.alternativeTab.paymentInformation.title')} />
      {isShowInstruction ? (
        <SectionWithBody
          description={t('accountWithdrawPage.alternativeTab.paymentInformation.description')}
          isHelperDescription
        />
      ) : (
        <ol>
          <li>
            {t(`accountWithdrawPage.alternativeTab.paymentInformation.step1.${paymentMethod}`)}
          </li>
          <li>
            {t(`accountWithdrawPage.alternativeTab.paymentInformation.step2.${paymentMethod}`)}
          </li>
          <li>
            {t(`accountWithdrawPage.alternativeTab.paymentInformation.step3.${paymentMethod}`)}
          </li>
          <li>
            {t(`accountWithdrawPage.alternativeTab.paymentInformation.step4.${paymentMethod}`)}
          </li>
        </ol>
      )}
    </div>
  );
};

export default PaymentInformation;
