import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';

import { ModalWithChildren } from '../../../../components/generic/ModalWithChildren';
import { selectCreateProfileErrorMessage } from '../../../../store/loads/selectors';

import './index.scss';

interface TermsOfServiceModalProps {
  text: string;
  open: boolean;
  onClose: () => void;
  onAgree: () => void;
}

export const TermsOfServiceModal: React.FC<TermsOfServiceModalProps> = ({ text, open, onClose, onAgree }) => {
  const { t } = useTranslation();
  const [hasRead, setHasRead] = React.useState<boolean>(false);
  const [isAgree, setIsAgree] = React.useState<boolean>(false);
  const requestHasError = useSelector(selectCreateProfileErrorMessage);

  const handleScroll = ({ currentTarget }: React.UIEvent<HTMLElement>) => {
    if (hasRead) {
      return;
    }

    if (currentTarget.scrollHeight - currentTarget.scrollTop === currentTarget.clientHeight) {
      setHasRead(true);
    }
  };

  const handleAgree = () => {
    // if (hasRead && isAgree) {
    //   onAgree();
    // }
    if (isAgree) {
      onAgree();
    }
  };

  React.useEffect(() => {
    if (requestHasError) {
      setHasRead(true);
      setIsAgree(true);
    } // keep agree after first try
  }, [requestHasError]);

  return (
    <ModalWithChildren isVisible={open} onClose={onClose}>
      <div className="terms-of-service-modal">
        <h2 className="terms-of-service-modal__title">{t('authPage.termsOfService.title')}</h2>
        <div className="terms-of-service-modal__text-wrapper" onScroll={handleScroll}>
          <ReactMarkdown className="terms-of-service-modal__text-wrapper-md">
            {text}
          </ReactMarkdown>
        </div>
        <div className="terms-of-service-modal__agreement">
          <label htmlFor="agreement">
            <input
              checked={isAgree}
              id="agreement"
              name="Agreement"
              onChange={() => setIsAgree(!isAgree)}
              type="checkbox"
            />
            {t('authPage.termsOfService.description')}
          </label>
        </div>
        <footer className="terms-of-service-modal__footer">
          <button className="button button--secondary" onClick={onClose} type="button">
            {t('authPage.termsOfService.cancel')}
          </button>
          <button
            className="button button--primary"
            // disabled={!hasRead || !isAgree}
            disabled={!isAgree}
            onClick={handleAgree}
            type="button"
          >
            {t('authPage.termsOfService.next')}
          </button>
        </footer>
      </div>
    </ModalWithChildren>
  );
};
