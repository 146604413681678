import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { interval, Subscription } from 'rxjs';

import { TZone } from '../../../@types/Profile.interface';
import ClockIcon from '../../../assets/svg/clock.svg';
import { defTimeZone, timeZones } from '../../../const';
import { changeTimeZone } from '../../../store/profile/actions';
import { selectTimeZone } from '../../../store/profile/selectors';

import './index.scss';

const checkDoubleNumbers = (time: number): string => {
  if (time < 10) {
    if (time < 0) {
      return `${24 + time}`;
    }
    return `0${time}`;
  }
  return `${time}`;
};

const calculateTime = (zone: number): string =>
  `${checkDoubleNumbers((new Date().getUTCHours() + zone) % 24)}:${checkDoubleNumbers(new Date().getUTCMinutes())}`;

export const TimeZone: React.FC = () => {
  const [selectedZone, setSelectedZone] = React.useState<TZone>(defTimeZone);
  const [time, setTime] = React.useState<string>('');
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const timeZone = useSelector(selectTimeZone);
  const selectRef = React.useRef<HTMLDivElement>(null);
  const listRef = React.useRef<HTMLUListElement>(null);
  const dispatch = useDispatch();

  const handleClickOutside = (e: Event) => {
    if (
      !listRef.current?.contains(e.target as Node) &&
      !selectRef.current?.contains(e.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  const setTimeZone = (time: TZone): void => {
    dispatch(changeTimeZone(time.zone));
    setTime(calculateTime(time.zone));
    setIsOpen(false);
  };

  const timeZoneSelected = (time: TZone) => time.zone >= 0 ? `+${time.zone}` : `${time.zone}`;

  React.useEffect(() => {
    setSelectedZone({
      ...selectedZone,
      zone: timeZone,
      time: calculateTime(timeZone),
    });
  }, [timeZone]);

  React.useEffect(() => {
    const sub: Subscription = interval(1000).subscribe(() => {
      setTime(calculateTime(selectedZone.zone));
    });

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      sub.unsubscribe();
    };
  }, [selectedZone]);

  return (
    <div className="header__time-zone dropdown">
      <div className="dropdown__icon-clock">
        <img alt="clock-icon" onClick={() => setIsOpen(!isOpen)} src={ClockIcon} />
      </div>
      <div
        className={isOpen ? 'tz-select__default drop-up' : 'tz-select__default'}
        onClick={() => setIsOpen(!isOpen)}
        ref={selectRef}
      >
        <span>{time}</span>
        &nbsp;
        <span className="gray2-color">{`(GMT${timeZoneSelected(selectedZone)})`}</span>
      </div>
      {isOpen && (
        <ul className="tz-select__dropdown dropdown--base m-color tz-close" id="js-tz-dropdown" ref={listRef}>
          {timeZones?.map((time) => (
            <li className="tz-select__item dropdown--item-style" key={time.id} onClick={() => setTimeZone(time)}>
              {calculateTime(time.zone)}
              {' '}
              &nbsp;
              <span className="gray2-color">{`(GMT${timeZoneSelected(time)})`}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
