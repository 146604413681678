/* eslint-disable jsx-a11y/control-has-associated-label */
import { Control, Controller } from 'react-hook-form';
import ReactPhoneInput from 'react-phone-input-2';

import { ReactComponent as ClearIcon } from '../../../assets/svg/close.svg';

import 'react-phone-input-2/lib/style.css';
import './index.scss';

interface PhoneInputProps {
  control: Control;
  name: string;
  onClear?: () => void;
}

export const PhoneInput: React.FC<PhoneInputProps> = ({ control, onClear, name }) => (
  <div className="phone-input">
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value } }) => (
        <ReactPhoneInput
          onBlur={onBlur}
          onChange={onChange}
          placeholder="+"
          value={value}
        />
      )}
    />
    <button className="phone-input__end-button" onClick={onClear} type="button"><ClearIcon className="icon" /></button>
  </div>
);
