import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../rootReducer';

const selectProfileState = (state: RootState) => state.profile;

export const selectUserIsAuth = createSelector(selectProfileState, (profile) => profile.isAuth);

export const selectTimeZone = createSelector(selectProfileState, (profile) => profile.timeZone);

export const selectLang = createSelector(selectProfileState, (profile) => profile.lang);
