import { Action, combineReducers } from '@reduxjs/toolkit';

import { cardsReducer, CardsState } from './cards/reducer';
import { loadsReducer, LoadsState } from './loads/reducer';
import { modalReducer, ModalState } from './modals/reducer';
import { operationsReducer, OperationsState } from './operations/reducer';
import { profileReducer, ProfileState } from './profile/reducer';
import { referralsReducer, ReferralsState } from './referrals/reducer';
import { userReducer, UserState } from './user/reducer';
import { withdrawalsReducer, WithdrawalState } from './withdrawals/reducer';

export type RootState = Readonly<{
  loads: LoadsState,
  modal: ModalState,
  profile: ProfileState,
  user: UserState,
  cards: CardsState,
  operations: OperationsState,
  referrals: ReferralsState,
  withdrawals: WithdrawalState,
}>;

export const rootReducer = combineReducers<RootState, Action>({
  loads: loadsReducer,
  modal: modalReducer,
  profile: profileReducer,
  user: userReducer,
  cards: cardsReducer,
  operations: operationsReducer,
  referrals: referralsReducer,
  withdrawals: withdrawalsReducer,
});
