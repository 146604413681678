import { createAction } from '@reduxjs/toolkit';

import { PageFilter, PageMeta, Params } from '../../@types/Common.interface';
import { Operation } from '../../@types/Operation.interface';

export const fetchOperations = createAction<Params>('FETCH_OPERATIONS');

export const fetchOperationsSuccess = createAction('FETCH_OPERATIONS_SUCCESS');

export const fetchOperationsFailed = createAction<string>('FETCH_OPERATIONS_FAILED');

export const setOperations = createAction<Operation[]>('SET_OPERATIONS');

export const setDiagramOperations = createAction<Operation[]>('SET_DIAGRAM_OPERATIONS');

export const setOperationsMeta = createAction<null | PageMeta>('SET_OPERATIONS_META');

export const setOperationsFilter = createAction<null | PageFilter>('SET_OPERATIONS_FILTER');

export const setOperationsPageNumber = createAction<number>('SET_OPERATIONS_PAGE_NUMBER');
