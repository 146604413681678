import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AuthPage } from '../../pages/AuthPage';
import { routes } from '../../routes';
import { selectUserIsAuth } from '../../store/profile/selectors';
import PrivateRoute from '../PrivateRoute';

import './index.scss';

export const Main: FC = () => {
  const isAuth = useSelector(selectUserIsAuth);

  return isAuth ? (
    <div className="main">
      <div className="routing-component">
        <Switch>
          {routes?.map(({ path, component, exact }, i) => (
            <Route
              component={component}
              exact={exact}
              key={i.toString(36)}
              path={path}
            />
          ))}
          <Redirect to="/" />
        </Switch>
      </div>
    </div>
  ) : (
    <AuthPage />
  );
};
