import React, { FC } from 'react';

import './index.scss';

const ConfirmModalBody: FC = ({ children }) => (
  <div className='confirm-modal-body'>
    {children}
  </div>
);

export default ConfirmModalBody;
