import { Link } from 'react-router-dom';

import './index.scss';

export const Success: React.FC = () => (
  <div className="form card password-recover-success">
    <h2 className="form__title password-recover-success__title">Successfully!</h2>
    <p className="form__text password-recover-success__text">Password have been sent to your e-mail</p>
    <Link className="password-recover-success__link" to="/">Return to login &rarr;</Link>
  </div>
);
