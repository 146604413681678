import React, { ChangeEvent, FC } from 'react';

import './index.scss';

interface IInput {
  title?: string;
  value?: string;
  mask?: string;
  placeholder?: string;
  image?: any;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  type: 'number' | 'text';
  inputClassName?: string;
}

const Input: FC<IInput> = (
  {
    title,
    placeholder,
    image,
    mask,
    value,
    handleChange,
    className,
    type,
    inputClassName,
    ...rest
  },
) => (
  <div className={className ? `input-container ${className}` : 'input-container'}>
    {title && <div className="input-container__title">{title}</div>}
    <div className='input-container__wrapper'>
      <div className={inputClassName ? `${inputClassName} input-container__mask` : 'input-container__mask'}>
        {image && <img alt="mask" src={image} />}
        <input
          onChange={handleChange}
          placeholder={placeholder}
          type={type}
          value={value}
          {...rest}
        />
      </div>
    </div>
  </div>
);

export default Input;
