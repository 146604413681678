import { createSelector } from '@reduxjs/toolkit';

import { RegData } from '../../@types/Profile.interface';
import { RootState } from '../rootReducer';

const selectModalState = (state: RootState) => state.modal;

export const selectSuccessModalIsOpen = createSelector(selectModalState, (modal) => modal.successModal.isOpen);

export const selectAddCardModalIsOpen = createSelector(selectModalState, (modal) => modal.addCard.isOpen);

export const selectTermsOfServiceModalIsOpen = createSelector(selectModalState, (modal) => modal.termsOfService.isOpen);

export const selectTermsOfServiceModalData = createSelector(selectModalState,
  (modal): null | RegData => modal.termsOfService.data,
);
