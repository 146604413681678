export const routePaths: Record<string, string> = {
  homePage: '/',
  registrations: '/registrations',
  operations: '/operations',
  accountWithdraw: '/withdraw',
  auth: '/auth',
  withdrawalHistory: '/withdraw/history',
  passwordRecovery: '/password-recovery',
  setNewPassword: '/set-new-password',
  register: '/register',
  emailConfirmed: '/email-confirmed',
};
