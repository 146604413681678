import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../rootReducer';

const selectCardsState = (state: RootState) => state.cards;

const selectCardOperationsState = createSelector(selectCardsState, (cards) => cards.operations);

export const selectUserCards = createSelector(selectCardsState, (cards) => cards.items);

export const selectCardsAddedOn = createSelector(selectUserCards, (cards) => {
  if (!cards.length) {
    return null;
  }
  return cards[cards.length - 1].dateAddedOn;
});

export const selectCardOperations = createSelector(selectCardOperationsState, (operations) => operations.items);

export const selectCardOperationsMeta = createSelector(selectCardOperationsState, (operations) => operations.meta);

export const selectCardOperationsFilter = createSelector(selectCardOperationsState, (operations) => operations.filter);

export const selectCardOperationsPageNumber = createSelector(selectCardOperationsState, (operations) => operations.currentPage);
