import { createAction } from '@reduxjs/toolkit';

import { RegData } from '../../@types/Profile.interface';

export const closeSuccessModal = createAction('CLOSE_SUCCESS_MODAL');

export const openAddCardModal = createAction('OPEN_ADD_CARD_MODAL');

export const closeAddCardModal = createAction('CLOSE_ADD_CARD_MODAL');

export const openTermsOfServiceModal = createAction<RegData>('OPEN_TERMS_OF_SERVICE_MODAL');

export const closeTermsOfServiceModal = createAction('CLOSE_TERMS_OF_SERVICE_MODAL');
