import { createReducer } from '@reduxjs/toolkit';

import { createUser } from '../profile/actions';
import {
  closeAddCardModal,
  closeSuccessModal,
  closeTermsOfServiceModal,
  openAddCardModal,
  openTermsOfServiceModal,
} from './actions';

interface Modal {
  isOpen: boolean;
  data?: any;
}

export interface ModalState extends Record<string, Modal> {}

const initialState: ModalState = {
  successModal: {
    isOpen: false,
  },
  addCard: {
    isOpen: false,
  },
  termsOfService: {
    isOpen: false,
    data: null,
  },
};

export const modalReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(closeSuccessModal, (state) => {
      state.successModal.isOpen = false;
    })
    .addCase(openAddCardModal, (state) => {
      state.addCard.isOpen = true;
    })
    .addCase(closeAddCardModal, (state) => {
      state.addCard.isOpen = false;
    })
    .addCase(openTermsOfServiceModal, (state, action) => {
      state.termsOfService.isOpen = true;
      state.termsOfService.data = action.payload;
    })
    .addCase(closeTermsOfServiceModal, (state) => {
      state.termsOfService.isOpen = false;
      state.termsOfService.data = null;
    })
    .addCase(createUser, (state) => {
      state.termsOfService.isOpen = false;
      state.termsOfService.data = null;
    });
});
