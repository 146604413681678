import { createReducer } from '@reduxjs/toolkit';

import {
  fetchCardList,
  fetchCardListFailed,
  fetchCardListSuccess,
  fetchCardOperations,
  fetchCardOperationsFailed,
  fetchCardOperationsSuccess,
} from '../cards/actions';
import {
  fetchOperations,
  fetchOperationsFailed,
  fetchOperationsSuccess,
} from '../operations/actions';
import {
  createUser,
  createUserFailed,
  createUserSuccess,
  login,
  loginFailed,
  loginSuccess,
} from '../profile/actions';
import {
  fetchReferrals,
  fetchReferralsFailed,
  fetchReferralsSuccess,
} from '../referrals/actions';
import {
  fetchUserData,
  fetchUserDataFailed,
  fetchUserDataSuccess,
} from '../user/actions';
import {
  fetchWithdrawalTransactions,
  fetchWithdrawalTransactionsFailed,
  fetchWithdrawalTransactionsSuccess,
  withdrawalCancel,
  withdrawalCancelFailed,
  withdrawalCancelSuccess,
  withdrawalRequest,
  withdrawalRequestFailed,
  withdrawalRequestSuccess,
} from '../withdrawals/action';

type LoadSection =
  'profile' |
  'createProfile' |
  'user' |
  'cards' |
  'cardOperations' |
  'operations' |
  'referrals' |
  'withdrawalTransactions' |
  'withdrawalRequest' |
  'withdrawalCancel';

interface Load {
  isLoading: boolean;
  errorMessage: string;
}

export interface LoadsState extends Record<LoadSection, Load> {}

const initialState: LoadsState = {
  profile: {
    isLoading: false,
    errorMessage: '',
  },
  createProfile: {
    isLoading: false,
    errorMessage: '',
  },
  user: {
    isLoading: false,
    errorMessage: '',
  },
  cards: {
    isLoading: false,
    errorMessage: '',
  },
  cardOperations: {
    isLoading: false,
    errorMessage: '',
  },
  operations: {
    isLoading: false,
    errorMessage: '',
  },
  referrals: {
    isLoading: false,
    errorMessage: '',
  },
  withdrawalTransactions: {
    isLoading: false,
    errorMessage: '',
  },
  withdrawalRequest: {
    isLoading: false,
    errorMessage: '',
  },
  withdrawalCancel: {
    isLoading: false,
    errorMessage: '',
  },
};

export const loadsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(login, (state) => {
      state.profile.isLoading = true;
    })
    .addCase(loginSuccess, (state) => {
      state.profile.isLoading = false;
      state.profile.errorMessage = '';
    })
    .addCase(loginFailed, (state, action) => {
      state.profile.isLoading = false;
      state.profile.errorMessage = action.payload;
    })
    .addCase(createUser, (state) => {
      state.createProfile.isLoading = true;
    })
    .addCase(createUserSuccess, (state) => {
      state.createProfile.isLoading = false;
      state.createProfile.errorMessage = '';
    })
    .addCase(createUserFailed, (state, action) => {
      state.createProfile.isLoading = false;
      state.createProfile.errorMessage = action.payload;
    })
    .addCase(fetchUserData, (state) => {
      state.user.isLoading = true;
    })
    .addCase(fetchUserDataSuccess, (state) => {
      state.user.isLoading = false;
      state.user.errorMessage = '';
    })
    .addCase(fetchUserDataFailed, (state, action) => {
      state.user.isLoading = false;
      state.user.errorMessage = action.payload;
    })
    .addCase(fetchCardList, (state) => {
      state.cards.isLoading = true;
    })
    .addCase(fetchCardListSuccess, (state) => {
      state.cards.isLoading = false;
      state.cards.errorMessage = '';
    })
    .addCase(fetchCardListFailed, (state, action) => {
      state.cards.isLoading = false;
      state.cards.errorMessage = action.payload;
    })
    .addCase(fetchCardOperations, (state) => {
      state.cardOperations.isLoading = true;
    })
    .addCase(fetchCardOperationsSuccess, (state) => {
      state.cardOperations.isLoading = false;
      state.cardOperations.errorMessage = '';
    })
    .addCase(fetchCardOperationsFailed, (state, action) => {
      state.cardOperations.isLoading = false;
      state.cardOperations.errorMessage = action.payload;
    })
    .addCase(fetchOperations, (state) => {
      state.operations.isLoading = true;
    })
    .addCase(fetchOperationsSuccess, (state) => {
      state.operations.isLoading = false;
      state.operations.errorMessage = '';
    })
    .addCase(fetchOperationsFailed, (state, action) => {
      state.operations.isLoading = false;
      state.operations.errorMessage = action.payload;
    })
    .addCase(fetchReferrals, (state) => {
      state.referrals.isLoading = true;
    })
    .addCase(fetchReferralsSuccess, (state) => {
      state.referrals.isLoading = false;
      state.referrals.errorMessage = '';
    })
    .addCase(fetchReferralsFailed, (state, action) => {
      state.referrals.isLoading = false;
      state.referrals.errorMessage = action.payload;
    })
    .addCase(fetchWithdrawalTransactions, (state) => {
      state.withdrawalTransactions.isLoading = true;
      state.withdrawalTransactions.errorMessage = '';
    })
    .addCase(fetchWithdrawalTransactionsSuccess, (state) => {
      state.withdrawalTransactions.isLoading = false;
      state.withdrawalTransactions.errorMessage = '';
    })
    .addCase(fetchWithdrawalTransactionsFailed, (state, action) => {
      state.withdrawalTransactions.isLoading = false;
      state.withdrawalTransactions.errorMessage = action.payload;
    })
    .addCase(withdrawalRequest, (state) => {
      state.withdrawalRequest.isLoading = true;
      state.withdrawalRequest.errorMessage = '';
    })
    .addCase(withdrawalRequestSuccess, (state) => {
      state.withdrawalRequest.isLoading = false;
      state.withdrawalRequest.errorMessage = '';
    })
    .addCase(withdrawalRequestFailed, (state, action) => {
      state.withdrawalRequest.isLoading = false;
      state.withdrawalRequest.errorMessage = action.payload;
    })
    .addCase(withdrawalCancel, (state) => {
      state.withdrawalCancel.isLoading = true;
      state.withdrawalCancel.errorMessage = '';
    })
    .addCase(withdrawalCancelSuccess, (state) => {
      state.withdrawalCancel.isLoading = false;
      state.withdrawalCancel.errorMessage = '';
    })
    .addCase(withdrawalCancelFailed, (state, action) => {
      state.withdrawalCancel.isLoading = false;
      state.withdrawalCancel.errorMessage = action.payload;
    });
});
