import { AnyAction, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';

import { Dependencies } from '../@types/Epic.interface';
import { axiosInstance } from '../utils/axios';
import { rootEpic } from './rootEpic';
import { rootReducer, RootState } from './rootReducer';

const epicMiddleware = createEpicMiddleware<AnyAction, AnyAction, RootState, Dependencies>({
  dependencies: { location: window.location, http: axiosInstance },
});

const middlewares = [...getDefaultMiddleware({ thunk: false }), epicMiddleware];

export const store = configureStore({
  reducer: rootReducer,
  middleware: middlewares,
});

epicMiddleware.run(rootEpic);
