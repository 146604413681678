import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectUserDepositInfo } from '../../../store/user/selectors';

import './index.scss';

export const Deposit: React.FC = () => {
  const { t } = useTranslation();
  const deposit = useSelector(selectUserDepositInfo);

  return (
    <div className="deposit-block">
      <div className="deposit-block__right">
        <div>
          <span>{t('components.header.balance')}</span>
          <span>{t('components.header.users')}</span>
        </div>
        <div>
          <span>
            $
            {deposit.balance}
          </span>
          <span>{deposit.users || '-'}</span>
        </div>
      </div>
    </div>
  );
};
