import React, { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';

import dollar from '../../../../../assets/png/dollar.png';
import { FormButton } from '../../../../../components/generic/FormButton';
import Input from '../../../../../components/generic/Input';
import { IWithdrawal, TWithdrawal } from '../../index';

import './index.scss';

interface ICollectMoney {
  amount: string
  changeWithdrawalValues: (value: string, type: TWithdrawal) => void;
  errors: IWithdrawal;
  setErrors: (value: string, type: TWithdrawal) => void;
  isSubmit: number;
}

const CollectMoney: FC<ICollectMoney> = (
  {
    amount,
    changeWithdrawalValues,
    errors,
    setErrors,
    isSubmit,
  },
) => {
  const { t } = useTranslation();
  const handleChangeInput = (event: ChangeEvent<HTMLInputElement> | string) => {
    if (isSubmit) {
      if (typeof event === 'string') {
        setErrors('', 'amount');
        changeWithdrawalValues(event, 'amount');
      } else {
        if (+event.target.value < 10) {
          setErrors(t('accountWithdrawPage.alternativeTab.least10'), 'amount');
        } else if (+event.target.value > 10000) {
          setErrors(t('accountWithdrawPage.alternativeTab.more10000'), 'amount');
        } else {
          setErrors('', 'amount');
        }

        changeWithdrawalValues(event.target.value, 'amount');
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (typeof event === 'string') {
        changeWithdrawalValues(event, 'amount');
      } else {
        changeWithdrawalValues(event.target.value, 'amount');
      }
    }
  };

  return (
    <div className='collect-money'>
      <Input
        handleChange={handleChangeInput}
        image={dollar}
        inputClassName='collect-money__input'
        placeholder='15'
        title={t('accountWithdrawPage.alternativeTab.enterWithdrawAmount.collectMoney.title')}
        type="number"
        value={amount}
      />
      <div className='collect-money__select'>
        <div className='collect-money__select__text'>
          {t('accountWithdrawPage.alternativeTab.enterWithdrawAmount.collectMoney.orSelect')}
        </div>
      </div>
      <FormButton
        inner
        onClick={() => handleChangeInput('100')}
        text="$100"
      />
      <FormButton
        inner
        onClick={() => handleChangeInput('50')}
        text="$50"
      />
      <FormButton
        inner
        onClick={() => handleChangeInput('25')}
        text="$25"
      />
      <div className='collect-money__select'>
        <div className='collect-money__select__text error'>
          {errors.amount}
        </div>
      </div>
    </div>
  );
};

export default CollectMoney;
