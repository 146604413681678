import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Filter } from '../../components/generic/Filter';
import { Pagination } from '../../components/Pagination';
import { RegistrationTable } from '../../components/RegistrationTable';
import { selectReferralsIsLoading } from '../../store/loads/selectors';
import { fetchReferrals, setReferralsFilter, setReferralsPageNumber } from '../../store/referrals/actions';
import {
  selectReferrals,
  selectReferralsFilter,
  selectReferralsMeta,
  selectReferralsPageNumber,
} from '../../store/referrals/selectors';

import './index.scss';

export const RegistrationsPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(selectReferralsIsLoading);
  const items = useSelector(selectReferrals);
  const meta = useSelector(selectReferralsMeta);
  const filterValue = useSelector(selectReferralsFilter);
  const currentPage = useSelector(selectReferralsPageNumber);

  React.useEffect(() => {
    if (filterValue) {
      dispatch(fetchReferrals({ limit: 50, page: currentPage, ...filterValue }));
    } else {
      dispatch(fetchReferrals({ limit: 50, page: currentPage }));
    }
  }, [filterValue, currentPage]);

  React.useEffect(() => () => {
    dispatch(setReferralsFilter(null));
  }, []);

  return (
    <div className="registration-page">
      <h1 className="registration-page__title">{t('registrationsPage.title')}</h1>
      <div className="card">
        <Filter disabled={isLoading} onChange={(value) => dispatch(setReferralsFilter(value))} type="registration" />
        <div className="table-wrapper">
          <RegistrationTable data={items} />
          {meta && meta.total > meta.perPage && (
            <Pagination
              active={meta.currentPage}
              disableGutterBottom
              onChangePage={(value) => dispatch(setReferralsPageNumber(value))}
              pages={new Array<number>(meta.lastPage).fill(0)?.map((_, i) => i + 1)}
            />
          )}
        </div>
      </div>
    </div>
  );
};
