import { from, merge, of } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';

import { EpicFunc } from '../../@types/Epic.interface';
import API from '../../api';
import { fetchReferrals, fetchReferralsFailed, fetchReferralsSuccess, setReferralsMeta } from './actions';

export const referralEpic: EpicFunc = (actions$) =>
  actions$.pipe(
    filter(fetchReferrals.match),
    mergeMap((action$) =>
      from(API.fetchUserReferrals(action$.payload))
        .pipe(
          mergeMap(({ data }) =>
            merge(
              of(fetchReferralsSuccess(data.data)),
              of(setReferralsMeta(data.meta)),
            ),
          ),
          catchError((error) =>
            of(fetchReferralsFailed(error.message)),
          ),
        ),
    ),
  );
