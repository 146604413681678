import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SuccessIcon } from '../../../assets/svg/success.svg';

import './index.scss';

interface AddedOnBlockProps {
  addedOn: string;
}

export const AddedOnBlock: React.FC<AddedOnBlockProps> = ({ addedOn }) => {
  const { t } = useTranslation();

  return (
    <>
      <span className="card__column-description">{t('homePage.addedOn.title')}</span>
      <div className="added-on-block">
        <span>{addedOn}</span>
        <span>
          <SuccessIcon className="icon" />
          {t('homePage.addedOn.current')}
        </span>
      </div>
    </>
  );
};
