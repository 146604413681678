import { createReducer } from '@reduxjs/toolkit';

import { Lang } from '../../@types/Profile.interface';
import { defLang } from '../../const';
import { changeLang, changeTimeZone, loginSuccess, logoutSuccess } from './actions';

export interface ProfileState {
  isAuth: boolean;
  accessToken: null | string;
  refreshToken: null | string;
  expiresIn: null | number;
  lang: Lang;
  timeZone: number;
}

const token = localStorage.getItem('access_token');

const initialState: ProfileState = {
  isAuth: true,
  accessToken: token,
  refreshToken: localStorage.getItem('refresh_token'),
  expiresIn: null,
  lang: defLang,
  timeZone: 3,
};

export const profileReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(loginSuccess, (state, { payload }) => {
      state.isAuth = true;
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
      state.expiresIn = payload.expiresIn;
    })
    .addCase(logoutSuccess, (state) => {
      state.isAuth = false;
      state.accessToken = null;
      state.refreshToken = null;
      state.expiresIn = null;
    })
    .addCase(changeTimeZone, (state, action) => {
      state.timeZone = action.payload;
    }).addCase(changeLang, (state, action) => {
      state.lang = action.payload;
    });
});
