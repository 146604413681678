import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useClickAway } from 'ahooks';

import { Lang } from '../../../@types/Profile.interface';
import { langs } from '../../../const';
import { changeLang } from '../../../store/profile/actions';
import { selectLang } from '../../../store/profile/selectors';

import './index.scss';

export const LangSelect: React.FC = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const ref = React.useRef<HTMLDivElement>(null);
  const lang = useSelector(selectLang);
  const { i18n } = useTranslation();

  const close = React.useCallback(() => setIsOpen(false), []);

  const handleSelect = (event: React.MouseEvent<HTMLLIElement>, value: Lang) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(changeLang(value));
    i18n.changeLanguage(value.lang);
    close();
  };

  useClickAway(close, ref);

  useEffect(() => {
    const lang = langs.find((el) => el.lang === i18n.language);

    if (lang) {
      dispatch(changeLang(lang));
    }
  }, []);

  return (
    <div className="header__intl dropdown" ref={ref}>
      <div className="intl__default icon--size" onClick={() => setIsOpen(!isOpen)}>
        <img
          alt="flag-default"
          height="43"
          src={lang.src}
        />
      </div>
      {isOpen && (
        <ul className="intl__dropdown dropdown--base m-color tz-close">
          {langs?.map((lang) => (
            <li
              className="intl__item dropdown--item-style"
              key={lang.id}
              onClick={(event) => handleSelect(event, lang)}
            >
              <img alt="flag-default" className="icon--size" src={lang.src} />
              <span>{lang.lang.toUpperCase()}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
