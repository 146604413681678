import { ReactComponent as ErrorIcon } from '../../../assets/svg/error.svg';

import './index.scss';

interface WithErrorMessageProps {
  error?: string;
}

export const withErrorMessage =
  <T extends WithErrorMessageProps>(Component: React.ComponentType<T>): React.FC<WithErrorMessageProps & T> =>
    ({ error, ...props }) => (
      <div className="with_error_message">
        <Component {...props as T} />
        {error && (
          <div className="with_error_message__content">
            <ErrorIcon />
            {error}
          </div>
        )}
      </div>
    );
