import React, { ChangeEvent, FC } from 'react';

import './index.scss';

interface ICheckbox {
  checked?: boolean;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  name?: string;
}

const Checkbox: FC<ICheckbox> = ({ checked = false, handleChange, className, name }) => (
  <div className={className ? `checkbox ${className}` : 'checkbox'}>
    <input
      checked={checked}
      name={name}
      onChange={handleChange}
      type='checkbox'
    />
    <div className={`checkbox__fake ${checked ? 'checked' : ''}`} />
  </div>
);

export default Checkbox;
