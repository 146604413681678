import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './index.scss';

export const EmailConfirmationSuccess: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="auth-page">
      <div className="auth-page__form-wrapper">
        <div className="form card email-confirmation-success">
          <h2 className="form__title email-confirmation-success__title">{t('authPage.emailConfirmationSuccess.title')}</h2>
          <p className="form__text">
            {t('authPage.emailConfirmationSuccess.verified')}
            <br />
            {t('authPage.emailConfirmationSuccess.please')}
            {' '}
            <Link to="/">{t('authPage.emailConfirmationSuccess.login')}</Link>
            {' '}
            {t('authPage.emailConfirmationSuccess.continue')}
          </p>
          <Link className="email-confirmation-success__link" to="/">
            {t('authPage.emailConfirmationSuccess.returnToLogin')}
            &rarr;
          </Link>
        </div>
      </div>
    </div>
  );
};
