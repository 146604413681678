import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../rootReducer';

const selectOperationState = (state: RootState) => state.operations;

export const selectOperations = createSelector(selectOperationState, (operations) => operations.items);

export const selectDiagramOperations = createSelector(selectOperationState, (operations) => operations.diagramItems);

export const selectOperationsMeta = createSelector(selectOperationState, (operations) => operations.meta);

export const selectOperationsFilter = createSelector(selectOperationState, (operations) => operations.filter);

export const selectOperationsCurrentPage = createSelector(selectOperationState, (operations) => operations.currentPage);
