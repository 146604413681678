import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useClickAway } from 'ahooks';
import cx from 'classnames';

import { Lang } from '../../../../@types/Profile.interface';
import { langs } from '../../../../const';
import { changeLang } from '../../../../store/profile/actions';
import { selectLang } from '../../../../store/profile/selectors';

import './index.scss';

export const Select: FC = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const [isActive, setIsActive] = React.useState<boolean>(false);
  const ref = React.useRef<HTMLDivElement>(null);
  const lang = useSelector(selectLang);

  const close = React.useCallback(() => setIsActive(false), []);

  const handleSelect = (event: React.MouseEvent<HTMLLIElement>, value: Lang) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(changeLang(value));
    i18n.changeLanguage(value.lang);
    close();
  };

  useClickAway(close, ref);

  useEffect(() => {
    const lang = langs.find((el) => el.lang === i18n.language);

    if (lang) {
      dispatch(changeLang(lang));
    }
  }, []);

  return (
    <div className={cx('lang-select-wrapper', { 'active': isActive })} ref={ref}>
      <div className="select" onClick={() => setIsActive(!isActive)}>
        <img
          alt={lang.lang}
          className="flag-icon"
          height={16}
          src={lang.authSrc}
          width={16}
        />
        <span className="lang">{lang.lang}</span>
      </div>
      {isActive && (
        <ul className="options">
          {langs?.map((option) => (
            <li
              className={cx('option', { 'selected': option.id === lang.id })}
              key={option.id}
              onClick={(event) => handleSelect(event, option)}
            >
              <img
                alt={option.lang}
                className="flag-icon"
                height={16}
                src={option.authSrc}
                width={16}
              />
              <span className="lang">{option.lang}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
