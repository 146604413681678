import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';

import { compose } from '@reduxjs/toolkit';

import { ReactComponent as Chevron } from '../../../assets/svg/chevron_top.svg';
import CopyIcon from '../../../assets/svg/file_copy.svg';
import { IconButton } from '../../../components/generic/IconButton';
import { withErrorMessage } from '../../../components/HOC/withErrorMessage';
import { withPreloader } from '../../../components/HOC/withPreloader';
import { selectUserErrorMessage, selectUserIsLoading } from '../../../store/loads/selectors';
import { RootState } from '../../../store/rootReducer';
import { selectUserReferralClick, selectUserReferralLink } from '../../../store/user/selectors';

import './index.scss';

const ReferralLinkBlockCmp: React.FC = () => {
  const { t } = useTranslation();
  const referralLink = useSelector(selectUserReferralLink);
  const linkInputRef = React.useRef<HTMLInputElement>(null);

  const userClick = useSelector(selectUserReferralClick);

  const handleChangeLinkInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleCopyToClipboard = () => {
    linkInputRef.current?.focus();
    linkInputRef.current?.select();
    linkInputRef && document.execCommand('copy');
  };

  return (
    <section className='card home-page__card'>
      <h5 className="card__title">{t('homePage.referralLink.title')}</h5>
      <div className="card__content">
        <div className="card__column left">
          <span className="card__column-description">{t('homePage.referralLink.description')}</span>
          <div className="referral-link-block">
            <input
              className="referral-link-block__input"
              onChange={handleChangeLinkInput}
              onFocus={handleCopyToClipboard}
              ref={linkInputRef}
              type="text"
              value={referralLink}
            />
            <IconButton iconUrl={CopyIcon} onClick={handleCopyToClipboard} />
          </div>
        </div>
        <div className="card__column right">
          <span className="card__column-description">{t('homePage.referralLink.clicks')}</span>
          <div className="referral-link-block__clicks">
            <span className="counter">{(userClick !== null) ? userClick : 0}</span>
            <Chevron className="icon" />
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoading: selectUserIsLoading(state),
  error: selectUserErrorMessage(state),
});

export const ReferralLinkBlock = compose<React.FC>(
  connect(mapStateToProps),
  withErrorMessage,
  withPreloader,
)(ReferralLinkBlockCmp);
