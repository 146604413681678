import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import jwtDecode from 'jwt-decode';

import { store } from '../../store';
import { logout, startTokenSync } from '../../store/profile/actions';
import { selectUserIsAuth } from '../../store/profile/selectors';

const Test = () => <>...Loading</>;

const ContainerProvider: FC = ({ children }) => {
  const isAuth = useSelector(selectUserIsAuth);
  useEffect(() => {
    const token = localStorage.getItem('refresh_token');

    if (token) {
	  try {
	    const data: any = jwtDecode(token);
	    if (data.exp * 1000 > Date.now()) {
		  store.dispatch(startTokenSync());
	    } else {
		  store.dispatch(logout());
	    }
	  } catch {
	    console.error('Refresh token failed');
	  }
    }
  }, []);

  if (!isAuth && localStorage.refresh_token) return <Test />;

  return (
    <>
      {children}
    </>
  );
};

export default ContainerProvider;
