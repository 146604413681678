import { AxiosResponse } from 'axios';

import { OperationsResponse, Params, Response } from '../@types/Common.interface';
import { Card, User } from '../@types/Profile.interface';
import { Referral } from '../@types/Registration.interface';
import { axiosInstance } from '../utils/axios';
import { endpoints } from './endpoints';

export const fetchUserData = (): Promise<AxiosResponse<User>> =>
  axiosInstance.get(endpoints.main.user());

export const fetchUserReferrals = ({
  limit,
  page = 1,
  dateStart,
  dateFinish,
}: Params): Promise<AxiosResponse<Response<Referral>>> => {
  const requestParams: any = {
    limit,
    page,
  };
  if (dateStart && dateFinish) {
    requestParams.dateStart = dateStart;
    requestParams.dateFinish = dateFinish;
  }

  return axiosInstance.get(endpoints.main.referrals(), { params: requestParams });
};

export const fetchUserOperations = ({
  limit,
  page = 1,
  dateStart,
  dateFinish,
}: Params): Promise<AxiosResponse<OperationsResponse>> => {
  const requestParams: any = {
    limit,
    page,
  };
  if (dateStart && dateFinish) {
    requestParams.dateStart = dateStart;
    requestParams.dateFinish = dateFinish;
  }

  return axiosInstance.get(endpoints.main.operations(), { params: requestParams });
};

export const fetchUserCards = (): Promise<AxiosResponse<Card[]>> =>
  axiosInstance.get(endpoints.main.cards());

export const fetchOperationsByCardId = ({
  id,
  limit,
  page = 1,
  dateStart,
  dateFinish,
}: Params): Promise<AxiosResponse<OperationsResponse>> => {
  const requestParams: any = {
    cardId: id,
    limit,
    page,
  };
  if (dateStart && dateFinish) {
    requestParams.dateStart = dateStart;
    requestParams.dateFinish = dateFinish;
  }

  return axiosInstance.get(endpoints.main.cardOperations(), { params: requestParams });
};
