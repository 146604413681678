import { createReducer } from '@reduxjs/toolkit';

import { User } from '../../@types/Profile.interface';
import { fetchUserDataSuccess } from './actions';

export interface UserState extends User {}

const initialState: UserState = {
  id: null,
  login: '',
  amount: 0,
  clicks: 0,
  partnerLink: '',
  phoneNumber: '',
  registrationDate: null,
  locations: [],
  status: 'active',
  lastOperation: null,
  referalsCount: 0,
  agreementFrom: '',
  partnerShip: {
    id: null,
    percent: 0,
    minimalPayout: 0,
    comission: 0,
    dateAddedOn: null,
  },
  partnerDto: {
    id: null,
    login: '',
  },
};

export const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchUserDataSuccess, (state, action) => {
      // state.id = action.payload.id;
      // state.login = action.payload.login;
      // state.amount = action.payload.amount;
      // state.clicks = action.payload.clicks;
      // state.partnerLink = action.payload.partnerLink;
      // state.phoneNumber = action.payload.phoneNumber;
      // state.registrationDate = action.payload.registrationDate;
      // state.locations = action.payload.locations;
      // state.status = action.payload.status;
      // state.lastOperation = action.payload.lastOperation;
      // state.referalsCount = action.payload.referalsCount;
      // state.partnerShip = { ...action.payload.partnerShip };
      // state.partnerDto = { ...action.payload.partnerDto };
      // state.agreementFrom = action.payload.agreementFrom;
    });
});
