import { createAction } from '@reduxjs/toolkit';

import { PageFilter, PageMeta, Params } from '../../@types/Common.interface';
import { Referral } from '../../@types/Registration.interface';

export const fetchReferrals = createAction<Params>('FETCH_REFERRALS');

export const fetchReferralsSuccess = createAction<Referral[]>('FETCH_REFERRALS_SUCCESS');

export const fetchReferralsFailed = createAction<string>('FETCH_REFERRALS_FAILED');

export const setReferralsMeta = createAction<null | PageMeta>('SET_REFERRALS_META');

export const setReferralsFilter = createAction<null | PageFilter>('SET_REFERRALS_FILTER');

export const setReferralsPageNumber = createAction<number>('SET_REFERRALS_PAGE_NUMBER');
