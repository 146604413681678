import { createReducer } from '@reduxjs/toolkit';

import { PageFilter, PageMeta } from '../../@types/Common.interface';
import { Referral } from '../../@types/Registration.interface';
import { fetchReferralsSuccess, setReferralsFilter, setReferralsMeta, setReferralsPageNumber } from './actions';

export interface ReferralsState {
  items: Referral[],
  meta: null | PageMeta,
  filter: null | PageFilter;
  currentPage: number;
}

const initialState: ReferralsState = {
  items: [],
  meta: null,
  filter: null,
  currentPage: 1,
};

export const referralsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchReferralsSuccess, (state, action) => {
      // state.items = action.payload;
    })
    .addCase(setReferralsMeta, (state, action) => {
      // state.meta = action.payload;
    })
    .addCase(setReferralsFilter, (state, action) => {
      // state.filter = action.payload;
      // state.currentPage = 1;
    })
    .addCase(setReferralsPageNumber, (state, action) => {
      // state.currentPage = action.payload;
    });
});
