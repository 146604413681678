import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../rootReducer';

const selectWithdrawalState = (state: RootState) => state.withdrawals;

export const selectWithdrawalTransactions = createSelector(selectWithdrawalState,
  (state) => state.transactions);

export const selectCancelWithdrawalSuccess = createSelector(selectWithdrawalState,
  (state) => state.successCancelWithdrawal);

export const selectRequestWithdrawalSuccess = createSelector(selectWithdrawalState,
  (state) => state.successWithdrawal);

export const selectCommissionWithdrawl = createSelector(selectWithdrawalState,
  (state) => state.commission);
