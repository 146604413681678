import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { compose } from '@reduxjs/toolkit';
import cx from 'classnames';

import { Referral } from '../../@types/Registration.interface';
import { selectReferralsErrorMessage, selectReferralsIsLoading } from '../../store/loads/selectors';
import { selectTimeZone } from '../../store/profile/selectors';
import { RootState } from '../../store/rootReducer';
import { getStringFullDateWithTime } from '../../utils/converters';
import { withErrorMessage } from '../HOC/withErrorMessage';
import { withPreloader } from '../HOC/withPreloader';

import './index.scss';

interface RegistrationTableProps {
  data: Referral[];
}

const RegistrationTableCmp: React.FC<TWithTimeZone<RegistrationTableProps>> = ({ data, timeZone }) => {
  const { t } = useTranslation();

  return (
    <table cellPadding="0" cellSpacing="0" className={cx('table', 'registration-table')}>
      <colgroup>
        <col style={{ width: '37%' }} />
        <col style={{ width: '28%' }} />
        <col style={{ width: '28%' }} />
        <col style={{ width: '7%' }} />
      </colgroup>
      <thead className="thead">
        <tr className="row">
          <th className="cell">{t('components.registrationTable.column1')}</th>
          <th className="cell">{t('components.registrationTable.column2')}</th>
          <th className="cell">{t('components.registrationTable.column3')}</th>
          <th className="cell">{t('components.registrationTable.column4')}</th>
        </tr>
      </thead>
      <tbody className="tbody">
        {data?.map((row) => (
          <tr className="row" key={row.id}>
            <td className="cell">{getStringFullDateWithTime(row.registrationDate, timeZone)}</td>
            <td className="cell">{row.login}</td>
            <td className="cell">
              <div>{row.referalRegion.country}</div>
              <div>{row.referalRegion.state}</div>
              <div>{row.referalRegion.city}</div>
            </td>
            <td className={cx('cell', { 'passive': row.status === 'passive' })}>{row.status}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoading: selectReferralsIsLoading(state),
  error: selectReferralsErrorMessage(state),
  timeZone: selectTimeZone(state),
});

export const RegistrationTable = compose<React.FC<RegistrationTableProps>>(
  connect(mapStateToProps),
  withErrorMessage,
  withPreloader,
)(RegistrationTableCmp);
