import { SelectedOrder, SelectedOrders } from '../@types/Bets.interface';
import { Operation } from '../@types/Operation.interface';

export const isOrdersEqual = (
  order1: SelectedOrder,
  order2: SelectedOrder,
): boolean => (
  order1.orderBookId.event === order2.orderBookId.event &&
    order1.orderBookId.line === order2.orderBookId.line &&
    order1.orderBookId.result === order2.orderBookId.result
);

export const formatPlaceOrders = (selectedOrders: SelectedOrders): any => ({
  ask: selectedOrders.ask?.map(
    ({ coef, price, reserveBidPrice, orderBookId }) => ({
      coef: +coef,
      price: +price,
      reserveBidPrice,
      orderBookId,
    }),
  ),
  bid: selectedOrders.bid?.map(
    ({ coef, price, reserveBidPrice, orderBookId }) => ({
      coef: +coef,
      price: +price,
      reserveBidPrice,
      orderBookId,
    }),
  ),
});

export const compareOperationsByDate = (a: Operation, b: Operation): number => {
  if (a.date > b.date) {
    return 1;
  }
  if (a.date < b.date) {
    return -1;
  }
  return 0;
};
