import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';

import { compose } from '@reduxjs/toolkit';

import { UserLocation } from '../../../@types/Profile.interface';
import { withErrorMessage } from '../../../components/HOC/withErrorMessage';
import { withPreloader } from '../../../components/HOC/withPreloader';
import { selectUserErrorMessage, selectUserIsLoading } from '../../../store/loads/selectors';
import { selectTimeZone } from '../../../store/profile/selectors';
import { RootState } from '../../../store/rootReducer';
import { selectUserPartnershipInfo } from '../../../store/user/selectors';
import { getStringFullDateWithTime } from '../../../utils/converters';
import { AddedOnBlock } from '../AddedOnBlock';

import './index.scss';

const renderLocations = (locations: UserLocation[]) =>
  locations?.map(({ country, state, city }, i) => (
    <React.Fragment key={i.toString(36)}>
      {city ? `${city}, ` : ''}
      {state ? `${state}, ` : ''}
      {country}
      {locations.length - 1 !== i && '; '}
    </React.Fragment>
  ));

const RawPartnershipAgreement: React.FC = () => {
  const { t } = useTranslation();
  const partnership = useSelector(selectUserPartnershipInfo);
  const timeZone = useSelector(selectTimeZone);

  return (
    <section className="card home-page__card">
      <h5 className="card__title">{t('homePage.partnershipAgreement.title')}</h5>
      <div className="card__content">
        <div className="card__column left">
          <div className="partnership-agreement-block">
            <div className="column">
              <span>{t('homePage.partnershipAgreement.column1')}</span>
              <span>{t('homePage.partnershipAgreement.column2')}</span>
              <span>{t('homePage.partnershipAgreement.column3')}</span>
              <span>{t('homePage.partnershipAgreement.column4')}</span>
            </div>
            <div className="column">
              <span>
                {partnership.percent}
                %
              </span>
              <span>
                {partnership.minimalPayout}
                {' '}
                USD
              </span>
              <span>
                {partnership.comission}
                %
              </span>
              <span style={{ textTransform: 'uppercase' }}>{renderLocations(partnership.locations || [])}</span>
            </div>
          </div>
        </div>
        <div className="card__column right center">
          <AddedOnBlock addedOn={partnership.dateAddedOn ? getStringFullDateWithTime(partnership.dateAddedOn, timeZone) : '-'} />
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoading: selectUserIsLoading(state),
  error: selectUserErrorMessage(state),
});

export const PartnershipAgreement = compose<React.FC>(
  connect(mapStateToProps),
  withErrorMessage,
  withPreloader,
)(RawPartnershipAgreement);
