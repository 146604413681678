import {
  auth,
  refreshToken,
  register,
  sendPasswordRecoveryMail,
  setNewPassword,
} from './auth';
import {
  fetchOperationsByCardId,
  fetchUserCards,
  fetchUserData,
  fetchUserOperations,
  fetchUserReferrals,
} from './main';
import {
  getComissionInfo,
  getTransactions,
  withdrawalCancelRequest,
  withdrawalRequest,
} from './withdrawals';

export default {
  user: {
    auth,
    refreshToken,
    register,
    fetchUserData,
    sendPasswordRecoveryMail,
    setNewPassword,
  },
  cards: {
    fetch: fetchUserCards,
    fetchOperations: fetchOperationsByCardId,
  },
  withdrawals: {
    getTransactions,
    withdrawalRequest,
    withdrawalCancelRequest,
    getComissionInfo,
  },
  fetchUserOperations,
  fetchUserReferrals,
};
