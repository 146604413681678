import React from 'react';
import { connect } from 'react-redux';

import { compose } from '@reduxjs/toolkit';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

import { Operation } from '../../@types/Operation.interface';
import { selectOperationsErrorMessage, selectOperationsIsLoading } from '../../store/loads/selectors';
import { selectTimeZone } from '../../store/profile/selectors';
import { RootState } from '../../store/rootReducer';
import { getStringFullDateWithoutTime } from '../../utils/converters';
import { withErrorMessage } from '../HOC/withErrorMessage';
import { withPreloader } from '../HOC/withPreloader';

import './index.scss';

interface OperationsDiagramProps {
  data: Operation[];
  width?: number | string;
  height?: number | string;
}

const OperationsDiagramCmp: React.FC<TWithTimeZone<OperationsDiagramProps>> = ({
  data,
  width = '100%',
  height = 300,
  timeZone,
}) => {
  const formattedData = React.useMemo(
    () => data?.map((r) => ({ date: getStringFullDateWithoutTime(r.date, timeZone), balance: r.balance })),
    [data],
  );

  return (
    <ResponsiveContainer height={height} width={width}>
      <LineChart
        data={formattedData}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid stroke="#e5e5ed" strokeDasharray="1" />
        <XAxis
          dataKey="date"
          interval="preserveStart"
          stroke="#a5a5a8"
          tick={{ fontSize: 12, fill: '#000' }}
          type="category"
        />
        <YAxis
          dataKey="balance"
          stroke="#a5a5a8"
          tick={{ fontSize: 12, fill: '#000' }}
          type="number"
        />
        <Line
          dataKey="date"
          dot={false}
          stroke="#5a5ae1"
          strokeWidth={1.5}
        />
        <Line
          dataKey="balance"
          dot={false}
          stroke="#5a5ae1"
          strokeWidth={1.5}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoading: selectOperationsIsLoading(state),
  error: selectOperationsErrorMessage(state),
  timeZone: selectTimeZone(state),
});

export const OperationsDiagram = compose<React.FC<OperationsDiagramProps>>(
  connect(mapStateToProps),
  withErrorMessage,
  withPreloader,
)(OperationsDiagramCmp);
