import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';

import { AuthData } from '../../../@types/Profile.interface';
import { selectProfileErrorMessage, selectProfileIsLoading } from '../../../store/loads/selectors';
import { login } from '../../../store/profile/actions';
import { authSchema } from '../../../utils/validationSchemas';
import { Select } from './Select';

import './index.scss';

export const AuthForm: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { register, handleSubmit, formState: { errors, isValid } } = useForm({
    mode: 'all',
    resolver: yupResolver(authSchema),
  });
  const isLoading = useSelector(selectProfileIsLoading);
  const errorMessage = useSelector(selectProfileErrorMessage);

  const onSubmit: SubmitHandler<AuthData> = (data) => dispatch(login(data));

  return (
    <div className="auth-page">
      <div className="auth-page__form-wrapper">
        <form className="form auth-form card" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="form__title">{t('authPage.authForm.title')}</h2>

          <div className="form__group">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="form__label">
              {t('authPage.authForm.label')}
            </label>
            <input
              aria-invalid={errors.username ? 'true' : 'false'}
              className="input"
              type="text"
              {...register('username')}
            />
            {errors && errors.username && <span className="error" role="alert">{errors.username.message}</span>}
          </div>

          <div className="form__group">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="form__label">{t('authPage.authForm.password')}</label>
            <input
              aria-invalid={errors.password ? 'true' : 'false'}
              className="input"
              type="password"
              {...register('password')}
            />
            {errors && errors.password && <span className="error" role="alert">{errors.password.message}</span>}
          </div>

          <div className="form__footer">
            <div className="form__actions">
              <button className="button button--primary" disabled={!isValid || isLoading} type="submit">
                {t('authPage.authForm.signIn')}
              </button>
              <Link className="button button--secondary" to="/register">{t('authPage.authForm.createAnAccount')}</Link>
            </div>
            <Select />
          </div>

          {errorMessage && <span className="error" role="alert">{errorMessage}</span>}

          {/* <span className="error" role="alert">
          The email adress or phone number that you&apos;ve entered doesn&apos;t match any account.
          {' '}
          <Link to="/register">Sign up for an account.</Link>
        </span> */}
        </form>

        <Link className="link" to="/password-recovery">{t('authPage.authForm.forgotPassword')}</Link>
      </div>
    </div>
  );
};
