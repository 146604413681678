import { from, merge, of } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';

import { EpicFunc } from '../../@types/Epic.interface';
import API from '../../api';
import { mapOperation } from '../../api/map';
import {
  fetchCardList,
  fetchCardListFailed,
  fetchCardListSuccess,
  fetchCardOperations,
  fetchCardOperationsFailed,
  fetchCardOperationsSuccess,
  setCardOperationsMeta,
} from './actions';

export const cardsEpic: EpicFunc = (actions$) =>
  actions$.pipe(
    filter(fetchCardList.match),
    mergeMap(() =>
      from(API.cards.fetch())
        .pipe(
          mergeMap(({ data }) =>
            of(fetchCardListSuccess(data)),
          ),
          catchError((error) =>
            of(fetchCardListFailed(error.message)),
          ),
        ),
    ),
  );

export const cardOperationsEpic: EpicFunc = (actions$) =>
  actions$.pipe(
    filter(fetchCardOperations.match),
    mergeMap((action$) =>
      from(API.cards.fetchOperations(action$.payload))
        .pipe(
          mergeMap(({ data }) =>
            merge(
              of(fetchCardOperationsSuccess(data.operations?.map(mapOperation))),
              of(setCardOperationsMeta(data.meta)),
            ),
          ),
          catchError((error) =>
            of(fetchCardOperationsFailed(error.message)),
          ),
        ),
    ),
  );
