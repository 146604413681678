import React, { FC } from 'react';

import { ModalWithChildren } from '../ModalWithChildren';

import './confirm.modal.scss';

interface IConfirmModal {
  isVisible: boolean;
  handleClose: () => void;
}

const ConfirmModal: FC<IConfirmModal> = (
  {
    children,
    isVisible,
    handleClose,
  }) => (
    <ModalWithChildren className='confirm-modal' isVisible={isVisible} onClose={handleClose}>
      {children}
    </ModalWithChildren>
);

export default ConfirmModal;
