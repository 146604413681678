import React, {
  FC,
  FormEvent,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { IWithdrawalRequest } from '../../../@types/Withdrawal.interface';
import { selectUserInfo } from '../../../store/user/selectors';
import { withdrawalCommission, withdrawalRequest } from '../../../store/withdrawals/action';
import { selectCommissionWithdrawl } from '../../../store/withdrawals/selectors';
import EnterWithdrawAmount from './EnterWithdrawAmount';
import InformationModal from './InformationModal';
import Modal from './Modal';
import PaymentInformation from './PaymentInformation';
import PaymentMethodsSelect from './PaymentMethodsSelect';
import PendingWithdrawals from './PendingWithdrawals';

import './index.scss';

export interface IWithdrawal {
  paymentMethod: string;
  amount: string;
  address: string;
  card: string;
  phone: string;
}

export type TWithdrawal = keyof IWithdrawal;

export const AlternativeTab: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { email } = useSelector(selectUserInfo);
  const commission = useSelector(selectCommissionWithdrawl);
  const { id } = useParams<{ id: string }>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isInformationModal, setIsInformationModal] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<number>(0);
  const [errors, setErrors] = useState<IWithdrawal>({
    paymentMethod: '',
    amount: '',
    address: '',
    card: '',
    phone: '',
  });

  const [values, setValues] = useState<IWithdrawal>({
    paymentMethod: '',
    amount: '',
    address: '',
    card: '',
    phone: '',
  });

  const changeWithdrawalValues = (value: string, type: TWithdrawal) => {
    setValues((prevValues) => ({
      ...prevValues,
      [type]: value,
    }));
  };

  const setErrorUtils = (value: string, type: TWithdrawal) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [type]: value,
    }));
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmit((prev) => prev + 1);

    if (!values.paymentMethod) {
      setErrorUtils(t('accountWithdrawPage.alternativeTab.selectPaymentMethod'), 'paymentMethod');
    } else {
      setErrorUtils('', 'paymentMethod');
    }

    if (!values.amount) {
      setErrorUtils(t('accountWithdrawPage.alternativeTab.thisFieldIsRequired'), 'amount');
    } else if (+values.amount < 10) {
      setErrorUtils(t('accountWithdrawPage.alternativeTab.least10'), 'amount');
    } else if (+values.amount > 10000) {
      setErrorUtils(t('accountWithdrawPage.alternativeTab.more10000'), 'amount');
    } else {
      setErrorUtils('', 'paymentMethod');
    }

    if (values.paymentMethod === 'visa') {
      if (!values.card) {
        setErrorUtils(t('accountWithdrawPage.alternativeTab.thisFieldIsRequired'), 'card');
      } else if (values.card.length < 16) {
        setErrorUtils(t('accountWithdrawPage.alternativeTab.invalidCardNumber'), 'card');
      } else {
        setErrorUtils('', 'card');
      }
    } else if (values.paymentMethod === 'coinpayments') {
      if (!values.address) {
        setErrorUtils(t('accountWithdrawPage.alternativeTab.thisFieldIsRequired'), 'address');
      } else {
        setErrorUtils('', 'address');
      }
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleCloseInformation = () => {
    setIsInformationModal(false);
  };

  const handleConfirm = () => {
    const amountCommission = Number(values.amount) * commission / 100;
    const requestBitGo: IWithdrawalRequest = {
      amount: +values.amount - amountCommission,
      login: email,
      type: 'withdraw',
      paymentSystem: values.paymentMethod,
      currency: 'USDTTRC20',
      account: values.address,
    };

    const requestStripe = {
      amount: +values.amount - amountCommission,
      login: email,
      type: 'withdraw',
      paymentSystem: values.paymentMethod,
      account: values.card,
    };

    const requestQiwi = {
      amount: +values.amount - amountCommission,
      login: email,
      type: 'withdraw',
      paymentSystem: values.paymentMethod,
      account: values.phone,
    };

    const getRequest = () => {
      let data = {};
      switch (values.paymentMethod) {
        case 'visa':
          data = requestStripe;
          break;
        case 'coinpayments':
          data = requestBitGo;
          break;
        case 'qiwi':
          data = requestQiwi;
          break;
        default:
          data = requestStripe;
      }

      return data;
    };
    const data = getRequest();

    dispatch(withdrawalRequest(data as IWithdrawalRequest));
    setIsOpen(false);
    setIsInformationModal(true);
  };

  useEffect(() => {
    changeWithdrawalValues(id, 'paymentMethod');
  }, []);

  useEffect(() => {
    if (isSubmit) {
      const isOpenConfirmModal = Object.values(errors).filter((el) => !el);
      if (isOpenConfirmModal.length === 5) {
        setIsOpen(true);
      }
    }
  }, [isSubmit]);

  useEffect(() => {
    const initData = async () => {
      dispatch(withdrawalCommission(values.paymentMethod));
    };

    initData();
  }, [values.paymentMethod]);

  return (
    <div className="alt-methods-tab">
      <PaymentMethodsSelect changeWithdrawalValues={changeWithdrawalValues} paymentMethod={values.paymentMethod} />
      <PaymentInformation paymentMethod={values.paymentMethod} />
      <form onSubmit={handleSubmit}>
        <EnterWithdrawAmount
          changeWithdrawalValues={changeWithdrawalValues}
          commission={commission}
          errors={errors}
          isSubmit={isSubmit}
          setErrors={setErrorUtils}
          values={values}
        />
      </form>
      <PendingWithdrawals paymentMethod={values.paymentMethod} />
      <Modal
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        isOpen={isOpen}
        title={t('accountWithdrawPage.alternativeTab.modal.confirmQuestion')}
      >
        <span>
          {t('accountWithdrawPage.alternativeTab.modal.areYouSureYouWantToSend')}
          {' '}
          {(Number(values.amount) - (Number(values.amount) * commission / 100))}
          {' '}
          ($)
          {t('accountWithdrawPage.alternativeTab.modal.to')}
          {' '}
          {values.paymentMethod === 'visa' && values.card}
          {values.paymentMethod === 'coinpatments' && values.address}
          {values.paymentMethod === 'qiwi' && values.phone}
          {' '}
          {t('accountWithdrawPage.alternativeTab.modal.by')}
          {' '}
          {values.paymentMethod}
          ?
        </span>
      </Modal>
      <InformationModal handleClose={handleCloseInformation} isOpen={isInformationModal} />
    </div>
  );
};
