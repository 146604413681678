import { AnyAction } from '@reduxjs/toolkit';
import { combineEpics } from 'redux-observable';

import { Dependencies } from '../@types/Epic.interface';
import { cardOperationsEpic, cardsEpic } from './cards/epics';
import { operationEpic } from './operations/epics';
import {
  authEpic,
  createUserEpic,
  logoutEpic,
  refreshTokenEpic,
  sendPasswordRecoveryMailEpic,
  setNewPasswordEpic,
} from './profile/epics';
import { referralEpic } from './referrals/epics';
import { RootState } from './rootReducer';
import { userEpic } from './user/epics';
import {
  withdrawalCancelEpic,
  withdrawalCommissionEpic,
  withdrawalRequestEpic,
  withdrawalTransactionsEpic,
} from './withdrawals/epics';

export const rootEpic = combineEpics<AnyAction, AnyAction, RootState, Dependencies>(
  authEpic,
  refreshTokenEpic,
  logoutEpic,
  createUserEpic,
  sendPasswordRecoveryMailEpic,
  setNewPasswordEpic,
  userEpic,
  cardsEpic,
  cardOperationsEpic,
  referralEpic,
  operationEpic,
  withdrawalTransactionsEpic,
  withdrawalRequestEpic,
  withdrawalCancelEpic,
  withdrawalCommissionEpic,
);
