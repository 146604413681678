import { AxiosResponse } from 'axios';

import { AuthResponse } from '../@types/Common.interface';
import { RegData } from '../@types/Profile.interface';
import { AUTH_URL, CLIENT_ID, CLIENT_SECRET } from '../settings';
import { axiosInstance } from '../utils/axios';
import { endpoints } from './endpoints';

export const auth = (username: string, password: string): Promise<AxiosResponse<AuthResponse>> => axiosInstance.post(
  endpoints.auth.token(),
  {
    username,
    password,
    grant_type: 'password',
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  },
  {
    baseURL: AUTH_URL,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  },
);

export const refreshToken = (token: string): Promise<AxiosResponse<AuthResponse>> => axiosInstance.post(
  endpoints.auth.token(),
  {
    grant_type: 'refresh_token',
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    refresh_token: token,
  },
  {
    baseURL: AUTH_URL,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  },
);

export const register = (data: RegData): Promise<void | AxiosResponse> => {
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  return axiosInstance.post(endpoints.auth.registration(), data, config);
};

export const setNewPassword = (data: { email: string; password: string }): Promise<AxiosResponse<any>> => {
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  return axiosInstance.post(endpoints.auth.setNewPassword(), data, config);
};

export const sendPasswordRecoveryMail = (email: string): Promise<AxiosResponse<any>> =>
  axiosInstance.get(endpoints.auth.sendPasswordRecoveryMail(), { params: { email } });
