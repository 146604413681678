import React, { FC } from 'react';

import QuestionIcon from '../../../../assets/svg/question.svg';
import { IconButton } from '../../IconButton';

import './index.scss';

interface ISectionWithHeader {
  title: string;
  isHelperTitle: boolean;
}

const SectionWithHeader: FC<ISectionWithHeader> = ({
  title,
  isHelperTitle,
}) => (
  <div className="section-with-header">
    <span>{title}</span>
    {isHelperTitle && (
    <IconButton
      dark iconUrl={QuestionIcon} onClick={() => {}}
      small
    />
    )}
  </div>
);

export default SectionWithHeader;
