import { Lang, TZone } from '../@types/Profile.interface';
import qiwi from '../assets/img/qiwi.jpg';
import bitcoin from '../assets/png/bitcoin.png';
import stripe from '../assets/png/stripe.png';
import coinpayments from '../assets/png/usdt.png';
import visa from '../assets/png/visa-mastercard.png';
import AuthFlagDe from '../assets/svg/flags/auth/de.svg';
import AuthFlagDk from '../assets/svg/flags/auth/dk.svg';
import AuthFlagEn from '../assets/svg/flags/auth/en.svg';
import AuthFlagEs from '../assets/svg/flags/auth/es.svg';
import AuthFlagGr from '../assets/svg/flags/auth/gr.svg';
import AuthFlagIt from '../assets/svg/flags/auth/it.svg';
import AuthFlagPt from '../assets/svg/flags/auth/pt.svg';
import AuthFlagRu from '../assets/svg/flags/auth/ru.svg';
import AuthFlagSe from '../assets/svg/flags/auth/se.svg';
import FlagCn from '../assets/svg/flags/cn.png';
import FlagDe from '../assets/svg/flags/de.svg';
import FlagDk from '../assets/svg/flags/dk.svg';
import FlagEn from '../assets/svg/flags/en.svg';
import FlagEs from '../assets/svg/flags/es.svg';
import FlagFR from '../assets/svg/flags/fr.png';
import FlagGr from '../assets/svg/flags/gr.svg';
import FlagIt from '../assets/svg/flags/it.svg';
import FlagPt from '../assets/svg/flags/pt.svg';
import FlagRu from '../assets/svg/flags/ru.svg';
import FlagSe from '../assets/svg/flags/se.svg';
import FlagTr from '../assets/svg/flags/tr.png';
import lunu from '../assets/svg/luna.svg';

export const timeZones: TZone[] = [
  { id: -12, zone: -12 },
  { id: -11, zone: -11 },
  { id: -10, zone: -10 },
  { id: -9, zone: -9 },
  { id: -8, zone: -8 },
  { id: -7, zone: -7 },
  { id: -6, zone: -6 },
  { id: -5, zone: -5 },
  { id: -4, zone: -4 },
  { id: -3, zone: -3 },
  { id: -2, zone: -2 },
  { id: -1, zone: -1 },
  { id: 0, zone: 0 },
  { id: 1, zone: 1 },
  { id: 2, zone: 2 },
  { id: 3, zone: 3 },
  { id: 4, zone: 4 },
  { id: 5, zone: 5 },
  { id: 6, zone: 6 },
  { id: 7, zone: 7 },
  { id: 8, zone: 8 },
  { id: 9, zone: 9 },
  { id: 10, zone: 10 },
  { id: 11, zone: 11 },
  { id: 12, zone: 12 },
];

export const defTimeZone = timeZones[2];

export const langs: Lang[] = [
  // { id: 1, lang: 'de', src: FlagDe, authSrc: AuthFlagDe },
  // { id: 2, lang: 'dk', src: FlagDk, authSrc: AuthFlagDk },
  { id: 3, lang: 'en', src: FlagEn, authSrc: AuthFlagEn },
  // { id: 4, lang: 'es', src: FlagEs, authSrc: AuthFlagEs },
  // { id: 5, lang: 'gr', src: FlagGr, authSrc: AuthFlagGr },
  // { id: 6, lang: 'it', src: FlagIt, authSrc: AuthFlagIt },
  // { id: 7, lang: 'pt', src: FlagPt, authSrc: AuthFlagPt },
  // { id: 8, lang: 'se', src: FlagSe, authSrc: AuthFlagSe },
  // { id: 9, lang: 'ru', src: FlagRu, authSrc: AuthFlagRu },
  { id: 10, lang: 'ch', src: FlagCn, authSrc: FlagCn },
  { id: 11, lang: 'fr', src: FlagFR, authSrc: FlagFR },
  { id: 12, lang: 'tr', src: FlagTr, authSrc: FlagTr },
];

export const defLang: Lang = ({ id: 3, lang: 'en', src: FlagEn, authSrc: AuthFlagEn });

export const paymentMethods = [
  // {
  //   type: 'stripe',
  //   image: stripe,
  // },
  // {
  //   type: 'bitGo',
  //   image: bitcoin,
  // },
  // {
  //   type: 'lunu',
  //   image: lunu,
  // },
  {
    type: 'qiwi',
    image: qiwi,
  },
  {
    type: 'coinpayments',
    image: coinpayments,
  },
  {
    type: 'visa',
    image: visa,
  },
];
