import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageFilter } from '../../../@types/Common.interface';
import { DateSelector } from '../DateSelector';

import './index.scss';

type FilterType = 'registration' | 'statistics';

interface FilterProps {
  type: FilterType;
  onChange: (value: null | PageFilter) => void;
  disabled?: boolean;
}

export const Filter: React.FC<FilterProps> = ({ type, onChange, disabled = false }) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState<null | Date[]>(null);

  const handleApply = () => {
    if (value?.length === 2) {
      onChange({ dateStart: value[0].valueOf(), dateFinish: value[1].valueOf() });
    }
  };

  const handleReset = () => {
    setValue(null);
    onChange(null);
  };

  return (
    <div className="filter">
      <div className="selector">
        <div className="title">
          {type === 'registration' && t('components.filter.registrationDate')}
          {type === 'statistics' && t('components.filter.statisticsPeriod')}
        </div>
        <div className="wrapper">
          <DateSelector disabled={disabled} onChange={setValue} value={value} />
        </div>
      </div>
      <button
        className="button button--primary"
        disabled={disabled}
        onClick={handleApply}
        type="button"
      >
        {t('components.filter.label')}
      </button>
      <button
        className="button button--secondary"
        disabled={disabled}
        onClick={handleReset}
        type="button"
      >
        {t('components.filter.label')}
        &#160;
        {t('components.filter.default')}
      </button>
    </div>
  );
};
