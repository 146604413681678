/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';

import { RECAPTCHA_KEY } from '../../../settings';
import { sendPasswordRecoveryMail } from '../../../store/profile/actions';
import { passwordRecoverySchema } from '../../../utils/validationSchemas';
import { Success } from './Success';

import './index.scss';

interface FormValues {
  email: string;
}

export const PasswordRecoveryForm: React.FC = () => {
  const { t } = useTranslation();
  const [isCaptchaValid, setIsCaptchaValid] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const { register, handleSubmit, formState: { errors, isValid } } = useForm({
    mode: 'all',
    resolver: yupResolver(passwordRecoverySchema),
  });
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const success = params.get('success');
  const email = params.get('email');

  const onSubmit: SubmitHandler<FormValues> = ({ email }) => {
    localStorage.setItem('recoveryEmail', email);
    dispatch(sendPasswordRecoveryMail(email));
  };

  const handleChangeCaptcha = (token: null | string) => setIsCaptchaValid(!!token);

  if (success) {
    return <Success />;
  }

  return (
    <div className="auth-page">
      <div className="auth-page__form-wrapper">
        <div>
          {!email && (
            <form className="form password-recovery-form card" onSubmit={handleSubmit(onSubmit)}>
              <h2 className="form__title">{t('authPage.passwordRecoveryForm.title')}</h2>

              <div className="form__group">
                <label className="form__label">
                  {t('authPage.passwordRecoveryForm.label1')}
                  <br />
                  {t('authPage.passwordRecoveryForm.label2')}
                </label>

                <input
                  aria-invalid={errors.email ? 'true' : 'false'}
                  className="input"
                  type="text"
                  {...register('email')}
                />
                {errors && errors.email && <span className="error" role="alert">{errors.email.message}</span>}
              </div>

              <div className="form__group">
                <ReCAPTCHA
                  onChange={handleChangeCaptcha}
                  sitekey={RECAPTCHA_KEY}
                />
              </div>

              <div className="form__footer">
                <div className="form__actions">
                  <button
                    className="button button--primary"
                    disabled={!isValid || !isCaptchaValid}
                    type="submit"
                  >
                    {t('authPage.passwordRecoveryForm.next')}
                  </button>
                </div>
              </div>
            </form>
          )}

          <Link className="link" style={{ marginRight: 12 }} to="/">{t('authPage.passwordRecoveryForm.signIn')}</Link>
          <Link className="link" to="/register">{t('authPage.passwordRecoveryForm.registerNow')}</Link>
        </div>
      </div>
    </div>
  );
};
