import { from, of } from 'rxjs';
import {
  catchError,
  filter,
  mergeMap,
} from 'rxjs/operators';

import { EpicFunc } from '../../@types/Epic.interface';
import API from '../../api';
import {
  fetchWithdrawalTransactions,
  fetchWithdrawalTransactionsFailed,
  fetchWithdrawalTransactionsSuccess,
  withdrawalCancel,
  withdrawalCancelFailed,
  withdrawalCancelSuccess, withdrawalCommission, withdrawalCommissionFailed, withdrawalCommissionSuccess,
  withdrawalRequest,
  withdrawalRequestFailed,
  withdrawalRequestSuccess,
} from './action';

export const withdrawalTransactionsEpic: EpicFunc = (actions$) =>
  actions$.pipe(
    filter(fetchWithdrawalTransactions.match),
    mergeMap(({ payload: { limit, page } }) =>
      from(API.withdrawals.getTransactions(limit, page))
	    .pipe(
	      mergeMap(({ data }) =>
		    of(fetchWithdrawalTransactionsSuccess(data)),
	      ),
	      catchError((error) =>
		    of(fetchWithdrawalTransactionsFailed(error.message)),
	      ),
	    ),
    ),
  );

export const withdrawalRequestEpic: EpicFunc = (actions$) =>
  actions$.pipe(
    filter(withdrawalRequest.match),
    mergeMap(({ payload }) =>
      from(API.withdrawals.withdrawalRequest(payload))
        .pipe(
		  mergeMap( (data) =>
            of(withdrawalRequestSuccess(data.data)),
		  ),
          catchError((error) =>
            of(withdrawalRequestFailed(error.response.data))),
        ),
    ),
  );

export const withdrawalCancelEpic: EpicFunc = (action$) =>
  action$.pipe(
    filter(withdrawalCancel.match),
    mergeMap(({ payload }) =>
      from(API.withdrawals.withdrawalCancelRequest(payload))
        .pipe(
		  mergeMap((data) =>
		    of(withdrawalCancelSuccess(data.data)),
		  ),
          catchError((error) =>
            of(withdrawalCancelFailed(error.response.data)),
          ),
        ),
    ),
  );

export const withdrawalCommissionEpic: EpicFunc = (action$) =>
  action$.pipe(
    filter(withdrawalCommission.match),
    mergeMap(({ payload }) =>
      from(API.withdrawals.getComissionInfo(payload))
        .pipe(
		  mergeMap((data) => of(withdrawalCommissionSuccess(data.comissionForWithdraw))),
          catchError((error) => of(withdrawalCommissionFailed(error))),
        ),
    ),
  );
