import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  ConfirmModal,
  ConfirmModalAction,
  ConfirmModalBody,
  ConfirmModalHeader,
} from '../../../../components/generic/ConfirmModal';
import { withPreloader } from '../../../../components/HOC/withPreloader';
import { selectWithdrawalRequestErrorMessage, selectWithdrawalRequestIsLoading } from '../../../../store/loads/selectors';
import { selectRequestWithdrawalSuccess } from '../../../../store/withdrawals/selectors';

interface IInformationModal {
  handleClose: () => void;
  isOpen: boolean;
}

const InformationModal: FC<IInformationModal> = ({ handleClose, isOpen }) => {
  const { t } = useTranslation();
  const requestError = useSelector(selectWithdrawalRequestErrorMessage);
  const requsetSuccess = useSelector(selectRequestWithdrawalSuccess);
  const isLoading = useSelector(selectWithdrawalRequestIsLoading);
  const history = useHistory();
  const handleConfirm = () => {
    handleClose();
    history.push('/');
  };
  return (
    <ConfirmModal handleClose={handleClose} isVisible={isOpen}>
      <ConfirmModalHeader title={t('accountWithdrawPage.alternativeTab.modal.title')} />
      <ConfirmModalBody>
        {isLoading ?
          (
            <>
              Loading ...
            </>
          ) : (
            <>
              <span>{requestError || t('accountWithdrawPage.alternativeTab.modal.error')}</span>
            </>
          )}
      </ConfirmModalBody>
      <ConfirmModalAction
        cancelText={t('accountWithdrawPage.alternativeTab.modal.cancelText')}
        confirmText={t('accountWithdrawPage.alternativeTab.modal.confirmText')}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
      />
    </ConfirmModal>
  );
};

export default InformationModal;
