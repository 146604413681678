import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import QuestionIcon from '../../assets/svg/question.svg';
import AttentionModal from '../../components/generic/AttentionModal';
import { IconButton } from '../../components/generic/IconButton';
import { AlternativeTab } from './AlternativeTab';

import './index.scss';

export const AccountWithdrawPage: React.FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const { t } = useTranslation();

  return (
    <div className="withdraw-page">
      <div className="withdraw-page__header">
        <div className="withdraw-page__header__title">
          <span>{t('accountWithdrawPage.title')}</span>
        </div>
      </div>

      <main className="withdraw-page__main">
        <nav className="menu">
          <ul className="menu__list">
            <div
              className="menu__item active"
            >
              {t('accountWithdrawPage.menu.paymentMethods')}
            </div>
            <div className="spacer" />
            {/* <div className="menu__right-data"> */}
            {/*   {t('accountWithdrawPage.menu.minDeposit')} */}
            {/*   <span className="menu__right-value"> $6</span> */}
            {/* </div> */}
            {/* <div className="menu__right-data"> */}
            {/*   {t('accountWithdrawPage.menu.processing')} */}
            {/*   <span className="menu__right-value"> */}
            {/*     {t('accountWithdrawPage.menu.instant')} */}
            {/*   </span> */}
            {/* </div> */}
            {/* <div className="menu__right-data"> */}
            {/*   {t('accountWithdrawPage.menu.fee')} */}
            {/*   <span className="menu__right-value"> 0%</span> */}
            {/* </div> */}
            {/* <IconButton */}
            {/*   dark */}
            {/*   disabled */}
            {/*   iconUrl={QuestionIcon} */}
            {/*   onClick={() => { */}
            {/*   }} */}
            {/*   small */}
            {/* /> */}
          </ul>
        </nav>
        <AlternativeTab />
      </main>
      <AttentionModal isVisible={isVisible} setIsVisible={setIsVisible} />
    </div>
  );
};
