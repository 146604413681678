import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FormButton } from '../../FormButton';

import './index.scss';

interface IConfirmModalActions {
  handleClose: () => void;
  handleConfirm: () => void;
  cancelText?: string;
  confirmText?: string;
}

const ConfirmModalActions: FC<IConfirmModalActions> = ({
  handleConfirm,
  handleClose,
  confirmText,
  cancelText,
}) => {
  const { t } = useTranslation();

  return (
    <div className="confirm-modal-actions">
      <FormButton
        className="confirm-modal-actions__cancel"
        inner
        onClick={handleClose}
        text={cancelText || t('components.confirmModal.cancel')}
        type="button"
      />
      <FormButton
        className="confirm-modal-actions__add"
        inner
        onClick={handleConfirm}
        text={confirmText || t('components.confirmModal.confirm')}
        type="button"
      />
    </div>
  );
};

export default ConfirmModalActions;
