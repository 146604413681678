import { createReducer } from '@reduxjs/toolkit';

import { Withdrawal } from '../../@types/Profile.interface';
import {
  fetchWithdrawalTransactionsSuccess,
  withdrawalCancelSuccess, withdrawalCommission, withdrawalCommissionSuccess,
  withdrawalRequestSuccess,
} from './action';

export interface WithdrawalState extends Withdrawal {}

const initialState: WithdrawalState = {
  transactions: null,
  successWithdrawal: '',
  successCancelWithdrawal: '',
  commission: 0,
};

export const withdrawalsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchWithdrawalTransactionsSuccess, (state, action) => {
      // state.transactions = action.payload;
    })
    .addCase(withdrawalRequestSuccess, (state, action) => {
      // state.successWithdrawal = action.payload;
    })
    .addCase(withdrawalCancelSuccess, (state, action) => {
      // state.successCancelWithdrawal = action.payload;
    })
    .addCase(withdrawalCommissionSuccess, (state, action) => {
      // state.commission = action.payload;
    });
});
