import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { compose } from '@reduxjs/toolkit';
import cx from 'classnames';

import { Operation } from '../../@types/Operation.interface';
import { selectOperationsErrorMessage, selectOperationsIsLoading } from '../../store/loads/selectors';
import { selectTimeZone } from '../../store/profile/selectors';
import { RootState } from '../../store/rootReducer';
import { getStringFullDateWithTime } from '../../utils/converters';
import { withErrorMessage } from '../HOC/withErrorMessage';
import { withPreloader } from '../HOC/withPreloader';

import './index.scss';

interface OperationsTableProps {
  data: Operation[];
  gutterTop?: boolean;
}

const OperationsTableCmp: React.FC<TWithTimeZone<OperationsTableProps>> = ({ data, gutterTop = false, timeZone }) => {
  const { t } = useTranslation();

  return (
    <table cellPadding="0" cellSpacing="0" className={cx('table', 'operations-table', { 'gutterTop': gutterTop })}>
      <colgroup>
        <col style={{ width: '28%' }} />
        <col style={{ width: '28%' }} />
        <col style={{ width: '22%' }} />
        <col style={{ width: '22%' }} />
      </colgroup>

      <thead className="thead">
        <tr className="row">
          <th className="cell">{t('components.operationsTable.column1')}</th>
          <th className="cell">{t('components.operationsTable.column2')}</th>
          <th className="cell">
            {t('components.operationsTable.column3')}
            ($)
          </th>
          <th className="cell">
            {t('components.operationsTable.column4')}
            ($)
          </th>
        </tr>
      </thead>
      <tbody className="tbody">
        {data?.map((row) => (
          <tr className="row" key={row.id}>
            <td className="cell">{getStringFullDateWithTime(row.date, timeZone)}</td>
            <td className={cx('cell', { 'withdrawal': row.type === 'withdrawal fee' })}>{row.type}</td>
            <td className={cx('cell', { 'withdrawal': row.type === 'withdrawal fee' })}>{row.amount}</td>
            <td className="cell">{row.balance}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
const mapStateToProps = (state: RootState) => ({
  isLoading: selectOperationsIsLoading(state),
  error: selectOperationsErrorMessage(state),
  timeZone: selectTimeZone(state),
});

export const OperationsTable = compose<React.FC<OperationsTableProps>>(
  connect(mapStateToProps),
  withErrorMessage,
  withPreloader,
)(OperationsTableCmp);
