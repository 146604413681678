import { Redirect, Route, Switch } from 'react-router-dom';

import { AuthForm } from './AuthForm';
import { EmailConfirmationSuccess } from './EmailConfirmationSuccess';
import { PasswordRecoveryForm } from './PasswordRecoveryForm';
import { SetNewPassword } from './PasswordRecoveryForm/SetNewPassword';
import { RegisterForm } from './RegisterForm';

import './index.scss';

export const AuthPage: React.FC = () => (
  <div className="auth-page">
    <div className="auth-page__form-wrapper">
      <Switch>
        <Route component={AuthForm} exact path="/" />
        <Route component={PasswordRecoveryForm} exact path="/password-recovery" />
        <Route path='/set-new-password'>
          <SetNewPassword />
        </Route>
        <Route component={RegisterForm} exact path="/register" />
        <Route component={EmailConfirmationSuccess} exact path="/email-confirmed" />
        <Redirect to="/" />
      </Switch>
    </div>
  </div>
);
