import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../rootReducer';

const selectReferralState = (state: RootState) => state.referrals;

export const selectReferrals = createSelector(selectReferralState, (referrals) => referrals.items);

export const selectReferralsMeta = createSelector(selectReferralState, (referrals) => referrals.meta);

export const selectReferralsFilter = createSelector(selectReferralState, (referrals) => referrals.filter);

export const selectReferralsPageNumber = createSelector(selectReferralState, (referrals) => referrals.currentPage);
