import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { OperationsDiagram } from '../../components/OperationsDiagram';
import { OperationsTable } from '../../components/OperationsTable';
import { RegistrationTable } from '../../components/RegistrationTable';
import { routePaths } from '../../routes/paths';
import { fetchCardList } from '../../store/cards/actions';
import { selectCardsAddedOn } from '../../store/cards/selectors';
import { fetchOperations } from '../../store/operations/actions';
import { selectDiagramOperations, selectOperations } from '../../store/operations/selectors';
import { selectTimeZone } from '../../store/profile/selectors';
import { fetchReferrals } from '../../store/referrals/actions';
import { selectReferrals } from '../../store/referrals/selectors';
import { fetchUserData } from '../../store/user/actions';
import { getStringFullDateWithTime } from '../../utils/converters';
import { AddedOnBlock } from './AddedOnBlock';
import { LinkedCards } from './LinkedCards';
import { ModalSuccess } from './ModalSuccess';
import { PartnershipAgreement } from './PartnershipAgreement';
import PaymentMethods from './PaymentMethods';
import { ReferralLinkBlock } from './ReferralLinkBlock';

import './index.scss';

export const HomePage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cardAddedOn = useSelector(selectCardsAddedOn);
  const referrals = useSelector(selectReferrals);
  const operations = useSelector(selectOperations);
  const timeZone = useSelector(selectTimeZone);
  const diagramOperations = useSelector(selectDiagramOperations);

  React.useEffect(() => {
    dispatch(fetchUserData());
    dispatch(fetchCardList());
    dispatch(fetchReferrals({ limit: 5 }));
    dispatch(fetchOperations({ limit: 12 }));
  }, []);

  return (
    <div className="home-page">
      <div className="home-page__header">
        <h1 className="home-page__title">{t('homePage.title')}</h1>
      </div>

      <main className="home-page__main">
        <div className="home-page__column">
          <ReferralLinkBlock />

          <PartnershipAgreement />

          <section>
            <div className="card home-page__card">
              <Link
                className="add-withdrawal-link"
                to={`${routePaths.accountWithdraw}/default`}
              >
                {t('homePage.newWithdrawal')}
              </Link>
              <h5 className="card__title">{t('homePage.paymentMethods')}</h5>
              <div className="card__content">
                <div className="card__column left">
                  <PaymentMethods />
                  {/* <LinkedCards /> */}
                </div>
                <div className="card__column right center">
                  <AddedOnBlock addedOn={cardAddedOn ? getStringFullDateWithTime(cardAddedOn, timeZone) : '-'} />
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="card home-page__card">
              <h5 className="card__title">{t('homePage.lastRegistrations')}</h5>
              <div className="card__content">
                <RegistrationTable data={referrals} />
              </div>
            </div>
            <div className="read-more">
              <Link
                className="read-more__link"
                to={routePaths.registrations}
              >
                {t('homePage.readMore')}
                &gt;&gt;
              </Link>
            </div>
          </section>
        </div>

        <div className="home-page__column">
          <section>
            <div className="card home-page__card">
              <h5 className="card__title">{t('homePage.lastOperations')}</h5>
              <div className="card__content">
                <OperationsDiagram data={diagramOperations} />
              </div>
            </div>
            <div className="read-more">
              <Link
                className="read-more__link"
                to={routePaths.operations}
              >
                {t('homePage.readMore')}
                &gt;&gt;
              </Link>
            </div>
          </section>

          <section>
            <div className="card home-page__card">
              <h5 className="card__title">{t('homePage.lastOperations')}</h5>
              <div className="card__content">
                <OperationsTable data={operations} />
              </div>
            </div>
            <div className="read-more">
              <Link
                className="read-more__link"
                to={routePaths.operations}
              >
                {t('homePage.readMore')}
                &gt;&gt;
              </Link>
            </div>
          </section>
        </div>
      </main>

      <ModalSuccess />
    </div>
  );
};
