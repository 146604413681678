import React, { FC } from 'react';

import QuestionIcon from '../../../../assets/svg/question.svg';
import { IconButton } from '../../IconButton';

import './index.scss';

interface ISectionWithBody {
  isHelperDescription: boolean;
  description: string;
}

const SectionWithBody: FC<ISectionWithBody> = ({ isHelperDescription, description }) => (
  <div className="section-with-body">
    {isHelperDescription && (
      <IconButton
        dark
        iconUrl={QuestionIcon}
        onClick={() => {}}
        small
      />
    )}
    <div className="section-with-body__text">{description}</div>
  </div>
);

export default SectionWithBody;
