import React, { FC } from 'react';

import './index.scss';

const TableHead: FC = ({ children }) => (
  <thead className='thead-default'>
    {children}
  </thead>
);

export default TableHead;
