import { Operation, OperationFromServer } from '../@types/Operation.interface';

export const mapOperation = (operation: OperationFromServer): Operation => ({
  id: operation.id,
  userId: operation.userId,
  date: operation.operationDate,
  type: operation.operationType,
  amount: operation.operationAmount,
  balance: operation.operationBalance,
});
