import { createAction } from '@reduxjs/toolkit';

import { AuthData, AuthSuccess, Lang, RegData } from '../../@types/Profile.interface';

export const login = createAction<AuthData>('LOGIN');

export const loginSuccess = createAction<AuthSuccess>('LOGIN_SUCCESS');

export const loginFailed = createAction<string>('LOGIN_FAILED');

export const startTokenSync = createAction('START_TOKEN_SYNC');

export const createUser = createAction<RegData>('CREATE_USER');

export const createUserSuccess = createAction('CREATE_USER_SUCCESS');

export const createUserFailed = createAction<string>('CREATE_USER_FAILED');

export const logout = createAction('LOGOUT');

export const logoutSuccess = createAction('LOGOUT_SUCCESS');

export const changeTimeZone = createAction<number>('CHANGE_TIME_ZONE');

export const changeLang = createAction<Lang>('CHANGE_LANG');

export const sendPasswordRecoveryMail = createAction<string>('SEND_PASSWORD_RECOVERY_MAIL');

export const sendPasswordRecoveryMailSuccess = createAction('SEND_PASSWORD_RECOVERY_MAIL_SUCCESS');

export const sendPasswordRecoveryMailFailure = createAction<string>('SET_NEW_PASSWORD_RECOVERY_MAIL_FAILURE');

export const setNewPassword = createAction<{ email: string; password: string }>('SET_NEW_PASSWORD');

export const setNewPasswordSuccess = createAction('SET_NEW_PASSWORD_SUCCESS');

export const setNewPasswordFailure = createAction<string>('SET_NEW_PASSWORD_FAILURE');
