import { StrictMode } from 'react';
import { render } from 'react-dom';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { App } from './App';
import ContainerProvider from './components/ContainerProvider';
import { store } from './store';

import './styles/global.scss';
import './utils/i18next';

render(
  <StrictMode>
    <StoreProvider store={store}>
      <BrowserRouter>
        <ContainerProvider>
          <App />
        </ContainerProvider>
      </BrowserRouter>
    </StoreProvider>
  </StrictMode>,
  document.getElementById('root'),
);
