import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { PageMeta } from '../../../../../@types/Common.interface';
import { OperationFromServer } from '../../../../../@types/Operation.interface';
import { FormButton } from '../../../../../components/generic/FormButton';
import { Pagination } from '../../../../../components/Pagination';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '../../../../../components/Table';
import { selectWithdrawalCancelErrorMessage, selectWithdrawalCancelIsLoading } from '../../../../../store/loads/selectors';
import { selectTimeZone } from '../../../../../store/profile/selectors';
import { fetchWithdrawalTransactions, withdrawalCancel } from '../../../../../store/withdrawals/action';
import { selectCancelWithdrawalSuccess } from '../../../../../store/withdrawals/selectors';
import { getStringFullDateWithTime } from '../../../../../utils/converters';
import Modal from '../../Modal';

import './index.scss';

interface ITableWithdrawals {
  operations: OperationFromServer[];
  meta: PageMeta;
}

interface IValues {
  amount: number;
  date: number;
  paymentId: number;
}

const TableWithdrawals: FC<ITableWithdrawals> = ({ operations, meta }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isLoadingCancel = useSelector(selectWithdrawalCancelIsLoading);
  const errorCancel = useSelector(selectWithdrawalCancelErrorMessage);
  const successCancel = useSelector(selectCancelWithdrawalSuccess);
  const timeZone = useSelector(selectTimeZone);
  const [isOpenInformation, setIsOpenInformation] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [active, setActive] = useState<number>(1);
  const [values, setValues] = useState<IValues>({
    amount: 0,
    date: 0,
    paymentId: 0,
  });
  const handleChangePage = (value: number) => {
    dispatch(fetchWithdrawalTransactions({ page: value, limit: 4 }));
    setActive(value);
  };
  const handleOpenModal = (amount: number, date: number, paymentId: number) => {
    setValues({
      paymentId,
      date,
      amount,
    });
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleConfirm = () => {
    dispatch(withdrawalCancel(values.paymentId));
    handleClose();
  };

  const pages = () => {
    const result = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < meta.lastPage; i++) {
      result.push(i + 1);
    }

    return result;
  };
  const pagesResult = pages();

  useEffect(() => {
    if (isLoadingCancel) {
      setIsOpenInformation(true);
    }
  }, [isLoadingCancel]);
  return (
    <div className='table-withdrawals'>
      <Table className='table-withdrawals__container'>
        <TableHead>
          <TableRow>
            <TableCell>
              {t('accountWithdrawPage.alternativeTab.pendingWithdrawls.table.date')}
            </TableCell>
            <TableCell className='table-withdrawals__head-cell'>
              {t('accountWithdrawPage.alternativeTab.pendingWithdrawls.table.type')}
            </TableCell>
            <TableCell className='table-withdrawals__head-cell'>
              {t('accountWithdrawPage.alternativeTab.pendingWithdrawls.table.status')}
            </TableCell>
            <TableCell className='table-withdrawals__head-cell'>
              {t('accountWithdrawPage.alternativeTab.pendingWithdrawls.table.amount')}
            </TableCell>
            <TableCell className='table-withdrawals__head-cell'>
              {t('accountWithdrawPage.alternativeTab.pendingWithdrawls.table.actions')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {operations?.map(({
            operationAmount,
            operationType,
            operationDate,
            operationStatus,
            id,
          }) => (
            <TableRow>
              <TableCell>{getStringFullDateWithTime(operationDate, timeZone)}</TableCell>
              <TableCell className='table-withdrawals__cell'>{operationType}</TableCell>
              <TableCell className='table-withdrawals__cell'>{operationStatus}</TableCell>
              <TableCell className='table-withdrawals__cell'>{operationAmount}</TableCell>
              <TableCell className='table-withdrawals__cell'>
                <FormButton
                  className='table-withdrawals__button'
                  inner
                  onClick={() => handleOpenModal(operationAmount, operationDate as unknown as number, id)}
                  text={t('accountWithdrawPage.alternativeTab.pendingWithdrawls.table.cancelRequest')}
                />
              </TableCell>
            </TableRow>
          ),
          )}
        </TableBody>
      </Table>
      <Pagination
        active={active}
        onChangePage={handleChangePage}
        pages={pagesResult}
      />
      <Modal
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        isOpen={isOpen}
        title={t('accountWithdrawPage.alternativeTab.pendingWithdrawls.modal1.title')}
      >
        <span>
          {t('accountWithdrawPage.alternativeTab.pendingWithdrawls.modal1.text1')}
          {' '}
          {values.amount}
          {' '}
          ($)
          {' '}
          {t('accountWithdrawPage.alternativeTab.pendingWithdrawls.modal1.text2')}
          {' '}
          {getStringFullDateWithTime(values.date as unknown as string, timeZone)}
          ?
        </span>
      </Modal>
      <Modal
        handleClose={() => setIsOpenInformation(false)}
        handleConfirm={() => setIsOpenInformation(false)}
        isOpen={isOpenInformation}
        title={t('accountWithdrawPage.alternativeTab.pendingWithdrawls.modal2.title')}
      >
        {errorCancel || successCancel}
      </Modal>
    </div>
  );
};

export default TableWithdrawals;
