import React, { FC } from 'react';

import Checkbox from '../../../../components/generic/Checkbox';
import { paymentMethods } from '../../../../const';
import { TWithdrawal } from '../index';

import './index.scss';

interface IPaymentMethodsSelect {
  paymentMethod: string;
  changeWithdrawalValues: (value: string, type: TWithdrawal) => void;
}

const PaymentMethodsSelect: FC<IPaymentMethodsSelect> = ({ paymentMethod, changeWithdrawalValues }) => (
  <div className="payment-methods-select">
    {paymentMethods?.map(({ image, type }) => (
      <div className="payment-methods-select__image-container" onClick={() => changeWithdrawalValues(type, 'paymentMethod')}>
        <img alt={type} src={image} />
        {type === paymentMethod && (
        <Checkbox
          checked={type === paymentMethod}
          className="payment-methods-select__checkbox"
          name={type}
        />
        )}
      </div>
    ))}
  </div>
);

export default PaymentMethodsSelect;
