import React from 'react';
import Calendar from 'react-calendar';
import { useTranslation } from 'react-i18next';

import { useClickAway } from 'ahooks';
import cx from 'classnames';

import { getStringFullDatePeriod } from '../../../utils/converters';

import './index.scss';

interface DateSelectorProps {
  value: null | Date[];
  onChange: (value: null | Date[]) => void;
  disabled?: boolean;
}

export const DateSelector: React.FC<DateSelectorProps> = ({ value, onChange, disabled = false }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const ref = React.useRef<HTMLDivElement>(null);

  const handleChange = (value: Date | Date[]) => {
    if (value instanceof Array) {
      onChange(value);
    } else {
      onChange(null);
    }
  };

  useClickAway(() => setIsOpen(false), ref);

  return (
    <div className={cx('date-selector', { 'disabled': disabled })} ref={ref}>
      <div className="date-selector__main" onClick={() => setIsOpen(true)}>
        {value && value.length === 2 ? getStringFullDatePeriod(value[0], value[1]) : t('components.dateSelector.nothingSelected')}
      </div>
      {isOpen && (
        <div className="calendar">
          <Calendar
            locale="en-EN"
            minDetail="month"
            onChange={handleChange}
            returnValue="range"
            selectRange
            value={value}
          />
        </div>
      )}
    </div>
  );
};
