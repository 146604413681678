import { createAction } from '@reduxjs/toolkit';

import { PageFilter, PageMeta, Params } from '../../@types/Common.interface';
import { Operation } from '../../@types/Operation.interface';
import { Card } from '../../@types/Profile.interface';

export const fetchCardList = createAction('FETCH_CARD_LIST');

export const fetchCardListSuccess = createAction<Card[]>('FETCH_CARD_LIST_SUCCESS');

export const fetchCardListFailed = createAction<string>('FETCH_CARD_LIST_FAILED');

export const fetchCardOperations = createAction<Params>('FETCH_CARD_OPERATIONS');

export const fetchCardOperationsSuccess = createAction<Operation[]>('FETCH_CARD_OPERATIONS_SUCCESS');

export const fetchCardOperationsFailed = createAction<string>('FETCH_CARD_OPERATIONS_FAILED');

export const setCardOperationsMeta = createAction<null | PageMeta>('SET_CARD_OPERATIONS_META');

export const setCardOperationsFilter = createAction<null | PageFilter>('SET_CARD_OPERATIONS_FILTER');

export const setCardOperationsPageNumber = createAction<number>('SET_CARD_OPERATIONS_NUMBER');
