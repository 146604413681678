import React, { FC } from 'react';

import './index.scss';

interface IConfirmModalHeader {
  title: string;
}

const ConfirmModalHeader: FC<IConfirmModalHeader> = ({ title }) => (
  <div className='confirm-modal-header'>
    {title}
  </div>
);

export default ConfirmModalHeader;
