import './index.scss';

interface PaginationProps {
  pages: number[];
  active: number;
  onChangePage: (value: number) => void;
  disableGutterBottom?: boolean;
}

export const Pagination: React.FC<PaginationProps> = ({ pages, active, onChangePage, disableGutterBottom = false }) => (
  <div className={`pagination  ${disableGutterBottom && 'disableGutterBottom'}`}>
    {pages?.map((page) => (
      <div
        className={`pagination__item${
          active === page ? ' pagination__item_active' : ''
        }`}
        // eslint-disable-next-line react/no-array-index-key
        key={page}
        onClick={() => onChangePage(page)}
      >
        {page}
      </div>
    ))}
  </div>
);
