import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { compose } from '@reduxjs/toolkit';

import { Operation } from '../../../@types/Operation.interface';
import { withErrorMessage } from '../../../components/HOC/withErrorMessage';
import { withPreloader } from '../../../components/HOC/withPreloader';
import { selectCardOperationsErrorMessage, selectCardOperationsIsLoading } from '../../../store/loads/selectors';
import { selectTimeZone } from '../../../store/profile/selectors';
import { RootState } from '../../../store/rootReducer';
import { getStringFullDateWithTime } from '../../../utils/converters';

import './index.scss';

interface TableProps {
  data: Operation[];
}

export const RawTable: React.FC<TWithTimeZone<TableProps>> = ({ data, timeZone }) => {
  const { t } = useTranslation();

  return (
    <table cellPadding="0" cellSpacing="0" className="table">
      <colgroup>
        <col style={{ width: '28%' }} />
        <col style={{ width: '28%' }} />
        <col style={{ width: '22%' }} />
        <col style={{ width: '22%' }} />
      </colgroup>
      <thead className="thead">
        <tr className="row">
          <th className="cell">{t('components.operationsTable.column1')}</th>
          <th className="cell">{t('components.operationsTable.column2')}</th>
          <th className="cell">
            {t('components.operationsTable.column3')}
            ($)
          </th>
          <th className="cell">
            {t('components.operationsTable.column4')}
            ($)
          </th>
        </tr>
      </thead>
      <tbody className="tbody">
        {data?.map((row) => (
          <tr className="row" key={row.id}>
            <td className="cell">{getStringFullDateWithTime(row.date, timeZone)}</td>
            <td className="cell">{row.type}</td>
            <td className="cell">{row.amount}</td>
            <td className="cell">{row.balance}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoading: selectCardOperationsIsLoading(state),
  error: selectCardOperationsErrorMessage(state),
  timeZone: selectTimeZone(state),
});

export const Table = compose<React.FC<TableProps>>(
  connect(mapStateToProps),
  withErrorMessage,
  withPreloader,
)(RawTable);
