import React, { FC, HTMLProps } from 'react';

import cx from 'classnames';

import './FormButton.scss';

interface FormButtonProps extends HTMLProps<HTMLButtonElement> {
  text: string;
  inner?: boolean;
  innerOutlined?: boolean;
  outlined?: boolean;
  type?: 'submit' | 'button' | 'reset';
}

export const FormButton: FC<FormButtonProps> = ({
  text,
  type,
  className = '',
  outlined,
  inner,
  innerOutlined,
  onClick,
  disabled,
  ...rest
}) => (
  <button
    className={cx('form-button', {
      [className]: className,
      'form-button--outlined': outlined,
      'form-button--inner': inner,
      'form-button--inner-outlined': innerOutlined,
    })}
    disabled={disabled}
    onClick={onClick}
    type={type || 'button'}
    {...rest}
  >
    {text}
  </button>
);
