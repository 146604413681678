import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as SuccessIcon } from '../../../assets/svg/success--big.svg';
import { ModalWithChildren } from '../../../components/generic/ModalWithChildren';
import { closeSuccessModal } from '../../../store/modals/actions';
import { selectSuccessModalIsOpen } from '../../../store/modals/selectors';

import './index.scss';

export const ModalSuccess: React.FC = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectSuccessModalIsOpen);

  const handleClose = () => dispatch(closeSuccessModal());

  return (
    <ModalWithChildren
      className="modal-success"
      hideCloseButton
      isVisible={isOpen}
      onClose={handleClose}
    >
      <h3 className="modal-success__title">Success!</h3>
      <SuccessIcon className="modal-success__icon" />
      <p className="modal-success__text">
        $4510.00 withdrawal to your Visa 4542 is being processed.
      </p>
      <p className="modal-success__text">
        Expected in 2 - 5 working days.
      </p>
      <span className="modal-success__ref">Ref: 2000000404705622</span>
      <button className="modal-success__confirm-button" onClick={handleClose} type="button">OK</button>
    </ModalWithChildren>
  );
};
