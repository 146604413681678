import { format } from 'date-fns';

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const getUTCDate = (dateString: string) => {
  const date = new Date(dateString);

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
};

/**
 * Возвращает дату и время в виде строки типа 31.12.2020 15:00
 * @param date
 * @param timeZone
 */
export const getStringFullDateWithTime = (date: string, timeZone: number): string => {
  const timeZoneHours = 1000 * 60 * 60 * timeZone;
  const data = +date + timeZoneHours;

  return format(getUTCDate(data as unknown as string), 'dd.MM.yyyy HH:mm');
};

/**
 * Возвращает дату в виде строки типа 31.12.2020
 * @param date
 * @param timeZone
 */
export const getStringFullDateWithoutTime = (date: string, timeZone: number): string => {
  const timeZoneHours = 1000 * 60 * 60 * timeZone;
  const data = +date + timeZoneHours;

  return format(getUTCDate(data as unknown as string), 'dd.MM.yyyy');
};

export const getStringFullDate = (date: Date): string =>
  `${date.getDate()} ${MONTHS[date.getMonth()].slice(0, 3)} ${date
    .getFullYear()
    .toString()
    .slice(2)}`;

export const getStringFullDatePeriod = (start: Date, end: Date): string =>
  `${getStringFullDate(start)} - ${getStringFullDate(end)}`;

/**
 * Возвращает дату в виде строки типа 07/22
 * @param date
 * @param timeZone
 */
export const getExpirationDate = (date: string, timeZone: number): string => {
  const timeZoneHours = 1000 * 60 * 60 * timeZone;
  const data = +date + timeZoneHours;

  return format(getUTCDate(data as unknown as string), 'MM/yy');
};

export const getFormattedCardNumber = (value: string): string => {
  if (value.length < 4) {
    return '';
  }
  const mask = '••••  ••••  ••••';
  const last4digits = value.substr(value.length - 4);
  return `${mask} ${last4digits}`;
};
