import axios from 'axios';
import qs from 'querystring';

import { API_URL, AUTH_URL } from '../settings';
import { store } from '../store';

const axiosInstance = axios.create({
  baseURL: API_URL,
});

axiosInstance.interceptors.request.use((request) => {
  if (
    request.data &&
    request.headers['Content-Type'] === 'application/x-www-form-urlencoded'
  ) {
    request.data = qs.stringify(request.data);
  }
  return request;
});

axiosInstance.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const token = state.profile.accessToken;

    if (token && config.baseURL !== AUTH_URL) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

export { axiosInstance };
