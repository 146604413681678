import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Checkbox from '../Checkbox';
import { FormButton } from '../FormButton';
import { ModalWithChildren } from '../ModalWithChildren';

import './index.scss';

interface IAttentionModal {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
}

const AttentionModal: FC<IAttentionModal> = ({ isVisible, setIsVisible }) => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleClose = () => {
    if (isChecked) {
      setIsVisible(false);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(!isChecked);
  };

  return (
    <ModalWithChildren isVisible={isVisible} onClose={handleClose}>
      <div className="attention-modal__header">{t('components.attentionModal.title')}</div>
      <div className="attention-modal__content">
        <span>{t('components.attentionModal.description')}</span>
      </div>
      <div className="attention-modal__buttons">
        <div className="attention-modal__agree">
          <Checkbox
            checked={isChecked}
            handleChange={handleChange}
          />
          <span>{t('components.attentionModal.confirmTitle')}</span>
        </div>
        <FormButton
          className={
            isChecked ? 'attention-modal__add' : 'attention-modal__cancel'
          }
          inner
          onClick={handleClose}
          text={t('components.attentionModal.confirmButton')}
          type="button"
        />
      </div>
    </ModalWithChildren>
  );
};

export default AttentionModal;
