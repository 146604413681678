/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';

import { RegData } from '../../../@types/Profile.interface';
import termsOfService from '../../../assets/terms.md';
import { selectCreateProfileErrorMessage, selectCreateProfileIsLoading } from '../../../store/loads/selectors';
import { closeTermsOfServiceModal, openTermsOfServiceModal } from '../../../store/modals/actions';
import { selectTermsOfServiceModalData, selectTermsOfServiceModalIsOpen } from '../../../store/modals/selectors';
import { createUser } from '../../../store/profile/actions';
import { registerSchema } from '../../../utils/validationSchemas';
import { PhoneInput } from '../PhoneInput';
import { Success } from './Success';
import { TermsOfServiceModal } from './TermsOfServiceModal';

import './index.scss';

export const RegisterForm: React.FC = () => {
  const { t } = useTranslation();
  const [termsOfServiceText, setTermsOfServiceText] = React.useState<string>('');
  const dispatch = useDispatch();
  const { register, reset, getValues, handleSubmit, control, formState: { errors, isValid } } = useForm({
    mode: 'all',
    resolver: yupResolver(registerSchema),
  });
  const isLoading = useSelector(selectCreateProfileIsLoading);
  const errorMessage = useSelector(selectCreateProfileErrorMessage);
  const termsOfServiceIsOpen = useSelector(selectTermsOfServiceModalIsOpen);
  const regData = useSelector(selectTermsOfServiceModalData);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const success = params.get('success');
  const referal = params.get('referal');
  const { name } = register('phoneNumber');

  const onSubmit: SubmitHandler<RegData> = (data) => dispatch(openTermsOfServiceModal(data));

  const onAgreeTermsOfService = () => {
    if (!regData) {
      return;
    }
    const newRegData = {
      ...regData,
    };
    if (referal) {
      newRegData.referal = referal;
    }
    dispatch(createUser(newRegData));
  };

  React.useEffect(() => {
    axios.get(termsOfService)
      .then((res) => res.data)
      .then((text) => setTermsOfServiceText(text));
  }, []);

  if (success) {
    return <Success />;
  }

  return (
    <div className="auth-page">
      <div className="auth-page__form-wrapper">
        <div>
          <form autoComplete="off" className="form register-form card" onSubmit={handleSubmit(onSubmit)}>
            <h2 className="form__title">{t('authPage.registerForm.title')}</h2>

            <div className="form__group">
              <label className="form__label">
                {t('authPage.registerForm.firstName')}
                *
              </label>
              <input
                aria-invalid={errors.firstName ? 'true' : 'false'}
                className="input"
                type="text"
                {...register('firstName')}
              />
              {errors && errors.firstName && <span className="error" role="alert">{errors.firstName.message}</span>}
            </div>

            <div className="form__group">
              <label className="form__label">
                {t('authPage.registerForm.lastName')}
                *
              </label>
              <input
                aria-invalid={errors.lastName ? 'true' : 'false'}
                className="input"
                type="text"
                {...register('lastName')}
              />
              {errors && errors.lastName && <span className="error" role="alert">{errors.lastName.message}</span>}
            </div>

            <div className="form__group">
              <label className="form__label">
                {t('authPage.registerForm.phoneNumber')}
                *
              </label>
              <PhoneInput control={control} name={name} onClear={() => reset({ ...getValues, phoneNumber: '' })} />
              {errors && errors.phoneNumber && <span className="error" role="alert">{errors.phoneNumber.message}</span>}
            </div>

            <div className="form__group">
              <label className="form__label">
                {t('authPage.registerForm.email')}
                *
              </label>
              <input
                aria-invalid={errors.email ? 'true' : 'false'}
                className="input"
                type="text"
                {...register('email')}
              />
              {errors && errors.email && <span className="error" role="alert">{errors.email.message}</span>}
            </div>

            <div className="form__group">
              <label className="form__label">
                {t('authPage.registerForm.password')}
                *
              </label>
              <input
                aria-invalid={errors.password ? 'true' : 'false'}
                className="input"
                type="password"
                {...register('password')}
              />
              {errors && errors.password && <span className="error" role="alert">{errors.password.message}</span>}
            </div>

            <div className="form__group">
              <label className="form__label">
                {t('authPage.registerForm.confirmPassword')}
                *
              </label>
              <input
                aria-invalid={errors.confirmPassword ? 'true' : 'false'}
                className="input"
                type="password"
                {...register('confirmPassword')}
              />
              {errors && errors.confirmPassword && <span className="error" role="alert">{errors.confirmPassword.message}</span>}
            </div>

            <div className="form__footer">
              <div className="form__actions">
                <button className="button button--primary" disabled={!isValid || isLoading} type="submit">
                  {t('authPage.registerForm.createAnAccount')}
                </button>
                <Link className="button button--secondary" to="/">
                  {t('authPage.registerForm.alreadyHaveAnAccount')}
                </Link>
              </div>
            </div>

            {errorMessage && <span className="error" role="alert">{errorMessage}</span>}
          </form>

          <p className="disclaimer">
            {t('authPage.registerForm.afterRegistration')}
            <br />
            {t('authPage.registerForm.merged')}
          </p>

          {termsOfServiceIsOpen && (
            <TermsOfServiceModal
              onAgree={onAgreeTermsOfService}
              onClose={() => dispatch(closeTermsOfServiceModal())}
              open={termsOfServiceIsOpen}
              text={termsOfServiceText}
            />
          )}
        </div>
      </div>
    </div>
  );
};
