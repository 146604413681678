import React, { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';

import creditCard from '../../../../../assets/svg/credit-card.svg';
import Input from '../../../../../components/generic/Input';
import { IWithdrawal, TWithdrawal } from '../../index';

import './index.scss';

interface ICardContainer {
  changeWithdrawalValues: (value: string, type: TWithdrawal) => void;
  values: IWithdrawal;
  errors: IWithdrawal;
}

const CardContainer: FC<ICardContainer> = ({ values, changeWithdrawalValues, errors }) => {
  const { t } = useTranslation();
  const handleChangeCard = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.split('').filter((el) => +el).join('');

    changeWithdrawalValues(value, 'card');
  };

  const handleChangeAddress = (event: ChangeEvent<HTMLInputElement>) => {
    changeWithdrawalValues(event.target.value, 'address');
  };

  const handleChangePhone = (event: ChangeEvent<HTMLInputElement>) => {
    changeWithdrawalValues(event.target.value, 'phone');
  };

  const isShowCardNumber = values.paymentMethod === 'stripe' || values.paymentMethod === 'visa';
  const isPhone = values.paymentMethod === 'qiwi';
  const isCrypto = values.paymentMethod === 'coinpayments';

  return (
    <div className='card-container'>
      {isShowCardNumber && (
        <>
          <div className='card-container__header'>
            <span>{t('accountWithdrawPage.cardNumber')}</span>
            <InputMask
              mask='9999 9999 9999 9999'
              maskChar={null}
              onChange={handleChangeCard}
              placeholder='9999 9999 9999 9999'
              value={values.card}
            >
              {(inputProps: any) => (
                <Input
                  {...inputProps}
                  image={creditCard}
                  inputClassName='card-container__stripe'
                />
              )}
            </InputMask>
          </div>
          <div className='card-container__error'>
            <div className='card-container__error__text'>
              {errors.card}
            </div>
          </div>
        </>
      )}
      {isCrypto && (
        (
          <>
            <div className='card-container__header'>
              <span>{t('accountWithdrawPage.address')}</span>
              <Input
                handleChange={handleChangeAddress}
                inputClassName='card-container__bitgo'
                type='text'
                value={values.address}
              />
            </div>
            <div className='card-container__error'>
              <div className='card-container__error__text'>
                {errors.address}
              </div>
            </div>
          </>
        )
      )}

      {isPhone && (
        <>
          <div className='card-container__header'>
            <span>{t('accountWithdrawPage.phone')}</span>
            <div className="card-container__stripe input-container__mask">
              <InputMask
                mask="+7 (999) 999-99-99"
                maskChar={null}
                onChange={handleChangePhone}
                placeholder="+7 (999) 999-99-99"
              />
            </div>
          </div>
          <div className='card-container__error'>
            <div className='card-container__error__text'>
              {errors.address}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CardContainer;
