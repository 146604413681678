import React from 'react';
import { useHistory } from 'react-router-dom';

import { paymentMethods } from '../../../const';

import './index.scss';

const PaymentMethods = () => {
  const history = useHistory();
  const handleRedirect = (type: string) => {
    history.push(`/withdraw/${type}`);
  };

  return (
    <div className='payment-methods'>
      {paymentMethods?.map(({ image, type }) => (
        <div className='payment-methods__container' onClick={() => handleRedirect(type)}>
          <img alt={type} src={image} />
        </div>
      ))}
    </div>
  );
};

export default PaymentMethods;
