import { createAction } from '@reduxjs/toolkit';

import { OperationsResponse } from '../../@types/Common.interface';
import { IWithdrawalRequest } from '../../@types/Withdrawal.interface';

export const fetchWithdrawalTransactions = createAction<{  limit: number; page: number; }>('FETCH_WITHDRAWAL_TRANSACTIONS');

export const fetchWithdrawalTransactionsSuccess = createAction<OperationsResponse>('FETCH_WITHDRAWAL_TRANSACTIONS_SUCCESS');

export const fetchWithdrawalTransactionsFailed = createAction<string>('FETCH_WITHDRAWAL_TRANSACTIONS_FAILED');

export const withdrawalRequest = createAction<IWithdrawalRequest>('WITHDRAWAL_REQUEST');

export const withdrawalRequestSuccess = createAction<string>('WITHDRAWAL_REQUEST_SUCCESS');

export const withdrawalRequestFailed = createAction<string>('WITHDRAWAL_REQUEST_FAILED');

export const withdrawalCancel = createAction<number>('WITHDRAWAL_CANCEL');

export const withdrawalCancelSuccess = createAction<string>('WITHDRAWAL_CANCEL_SUCCESS');

export const withdrawalCancelFailed = createAction<string>('WITHDRAWAL_CANCEL_FAILED');

export const withdrawalCommission = createAction<string>('WITHDRAWAL_COMMISSION');

export const withdrawalCommissionSuccess = createAction<number>('WITHDRAWAL_COMMISSION_SUCCESS');

export const withdrawalCommissionFailed = createAction<string>('WITHDRAWAL_COMMISSION_FAILED');
