import { RouteProps } from 'react-router-dom';

import { AccountWithdrawPage } from '../pages/AccountWithdrawPage';
import { AuthForm } from '../pages/AuthPage/AuthForm';
import { EmailConfirmationSuccess } from '../pages/AuthPage/EmailConfirmationSuccess';
import { PasswordRecoveryForm } from '../pages/AuthPage/PasswordRecoveryForm';
import { SetNewPassword } from '../pages/AuthPage/PasswordRecoveryForm/SetNewPassword';
import { RegisterForm } from '../pages/AuthPage/RegisterForm';
import { HomePage } from '../pages/HomePage';
import { OperationsPage } from '../pages/OperationsPage';
import { RegistrationsPage } from '../pages/RegistrationsPage';
import { WithdrawalHistoryPage } from '../pages/WithdrawalHistoryPage';
import { routePaths } from './paths';

export const routes: RouteProps[] = [
  { path: routePaths.homePage, exact: true, component: HomePage },
  { path: routePaths.registrations, exact: true, component: RegistrationsPage },
  { path: routePaths.operations, exact: true, component: OperationsPage },
  { path: `${routePaths.accountWithdraw}/:id`, exact: true, component: AccountWithdrawPage },
  { path: `${routePaths.withdrawalHistory}/:id`, exact: true, component:  WithdrawalHistoryPage },

  { path: routePaths.auth, exact: true, component: AuthForm },
  { path: routePaths.setNewPassword, exact: true, component: SetNewPassword },
  { path: routePaths.register, exact: true, component: RegisterForm },
  { path: routePaths.emailConfirmed, exact: true, component: EmailConfirmationSuccess },
  { path: routePaths.passwordRecovery, exact: true, component: PasswordRecoveryForm },
];
