import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FormButton } from '../../../../../components/generic/FormButton';

import './index.scss';

interface IWithdrawalMoney {
  amount: string;
  error: string;
  commission: number;
}

const WithdrawalMoney: FC<IWithdrawalMoney> = ({ amount, error, commission }) => {
  const { t } = useTranslation();

  return (
    <div className="withdrawal-money__right-block">
      <div className="withdrawal-money__fee-wrapper">
        <div className="withdrawal-money__fee">
          {commission}
          .00 %
          {t('accountWithdrawPage.alternativeTab.enterWithdrawAmount.withdrawalMoney.fee')}
        </div>
        <div className="withdrawal-money__net-amount">
          {t('accountWithdrawPage.alternativeTab.enterWithdrawAmount.withdrawalMoney.netAmount')}
          :
          <span className="withdrawal-money__net-amount-value">
            {' '}
            {amount ? `${amount}.00` : '0.00'}
          </span>
        </div>
      </div>
      <FormButton
        inner
        text={t('accountWithdrawPage.alternativeTab.enterWithdrawAmount.withdrawalMoney.withdrawal')}
        type="submit"
      />
      {error}
    </div>
  );
};

export default WithdrawalMoney;
