import { AxiosResponse } from 'axios';

import { OperationsResponse } from '../@types/Common.interface';
import { IWithdrawalRequest } from '../@types/Withdrawal.interface';
import { axiosInstance } from '../utils/axios';

export const getTransactions = (limit: number, page: number): Promise<AxiosResponse<OperationsResponse>> => {
  const requestParams = {
    limit,
    page,
  };

  return axiosInstance.get('/operations', { params: requestParams });
};

export const withdrawalRequest = (data: IWithdrawalRequest): Promise<AxiosResponse<string>> =>
  axiosInstance.post('/user/withdraw', data);

export const withdrawalCancelRequest = (paymentId: number): Promise<AxiosResponse<string>> =>
  axiosInstance.post('/user/withdawCancel', { paymentId });

export const getComissionInfo = (paymentSystem: string) => {
  const queryString = new URLSearchParams({
    paymentSystem,
  }).toString();

  return fetch(`https://processing.si14.bet/getInfo?${queryString}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    },
  }).then((res) => res.json());
};
