import React from 'react';

import cx from 'classnames';

import { ReactComponent as CloseIcon } from '../../../assets/svg/close.svg';

import './index.scss';

interface ModalWithChildrenProps {
  isVisible: boolean;
  onClose?: any;
  className?: string;
  hideCloseButton?: boolean;
}

export const ModalWithChildren: React.FC<ModalWithChildrenProps> = ({
  isVisible,
  children,
  onClose,
  className,
  hideCloseButton = false,
}) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div
      className="modal-with-children"
      id="modal-with-children"
      onClick={onClose}
    >
      <div
        className={cx('modal-with-children__main', { [`${className}`]: !!className })}
        onClick={(e) => e.stopPropagation()}
      >
        {!hideCloseButton && (
          <div className="modal-with-children__close-btn" onClick={onClose}>
            <CloseIcon className="icon" />
          </div>
        )}
        {children}
      </div>
    </div>
  );
};
