import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../rootReducer';

const selectLoadsState = (state: RootState) => state.loads;

export const selectProfileIsLoading = createSelector(selectLoadsState, (loads) => loads.profile.isLoading);
export const selectProfileErrorMessage = createSelector(selectLoadsState, (loads) => loads.profile.errorMessage);

export const selectCreateProfileIsLoading = createSelector(selectLoadsState, (loads) => loads.createProfile.isLoading);
export const selectCreateProfileErrorMessage = createSelector(selectLoadsState, (loads) => loads.createProfile.errorMessage);

export const selectUserIsLoading = createSelector(selectLoadsState, (loads) => loads.user.isLoading);
export const selectUserErrorMessage = createSelector(selectLoadsState, (loads) => loads.user.errorMessage);

export const selectCardsIsLoading = createSelector(selectLoadsState, (loads) => loads.cards.isLoading);
export const selectCardsErrorMessage = createSelector(selectLoadsState, (loads) => loads.cards.errorMessage);

export const selectCardOperationsIsLoading = createSelector(selectLoadsState, (loads) => loads.cardOperations.isLoading);
export const selectCardOperationsErrorMessage = createSelector(selectLoadsState, (loads) => loads.cardOperations.errorMessage);

export const selectOperationsIsLoading = createSelector(selectLoadsState, (loads) => loads.operations.isLoading);
export const selectOperationsErrorMessage = createSelector(selectLoadsState, (loads) => loads.operations.errorMessage);

export const selectReferralsIsLoading = createSelector(selectLoadsState, (loads) => loads.referrals.isLoading);
export const selectReferralsErrorMessage = createSelector(selectLoadsState, (loads) => loads.referrals.errorMessage);

export const selectWithdrawalTransactionsIsLoading = createSelector(selectLoadsState,
  (loads) => loads.withdrawalTransactions.isLoading);

export const selectWithdrawalTransactionsErrorMessage = createSelector(selectLoadsState,
  (loads) => loads.withdrawalTransactions.errorMessage);

export const selectWithdrawalRequestErrorMessage = createSelector(selectLoadsState,
  (loads) => loads.withdrawalRequest.errorMessage);

export const selectWithdrawalRequestIsLoading = createSelector(selectLoadsState,
  (loads) => loads.withdrawalRequest.isLoading);

export const selectWithdrawalCancelIsLoading = createSelector(selectLoadsState,
  (loads) => loads.withdrawalCancel.isLoading);

export const selectWithdrawalCancelErrorMessage = createSelector(selectLoadsState,
  (loads) => loads.withdrawalCancel.errorMessage);
