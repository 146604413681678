import React, { FC } from 'react';

import './index.scss';

interface ITableCell {
  className?: string;
}

const TableCell: FC<ITableCell> = ({ children, className }) => (
  <td className={className ? `td-default ${className}` : 'td-default'}>
    {children}
  </td>
);

export default TableCell;
