import './IconButton.scss';

interface IconButtonProps {
  iconUrl: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  isLoading?: boolean;
  small?: boolean;
  dark?: boolean;
}

export const IconButton: React.FC<IconButtonProps> = ({
  iconUrl,
  onClick,
  disabled,
  isLoading,
  small,
  dark,
}) => (
  <button
    className={`icon-btn ${small ? 'icon-btn--small' : ''}  ${
      dark ? 'icon-btn--dark' : ''
    }`}
    disabled={disabled || isLoading}
    onClick={onClick}
    type="button"
  >
    <img
      alt="action"
      className={`icon-btn__img ${
        isLoading ? 'icon-btn__img--rotation' : ''
      }`}
      src={iconUrl}
    />
  </button>
);
