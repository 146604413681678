import { from, merge, of, timer } from 'rxjs';
import { catchError, filter, mapTo, mergeMap, tap, withLatestFrom } from 'rxjs/operators';

import { EpicFunc } from '../../@types/Epic.interface';
import API from '../../api';
import {
  createUser,
  createUserFailed,
  createUserSuccess,
  login,
  loginFailed,
  loginSuccess,
  logout,
  logoutSuccess,
  sendPasswordRecoveryMail,
  sendPasswordRecoveryMailFailure,
  sendPasswordRecoveryMailSuccess,
  setNewPassword,
  setNewPasswordFailure,
  setNewPasswordSuccess,
  startTokenSync,
} from './actions';

export const authEpic: EpicFunc = (actions$) =>
  actions$.pipe(
    filter(login.match),
    mergeMap((action$) =>
      from(API.user.auth(action$.payload.username, action$.payload.password))
        .pipe(
          tap(({ data }) => {
            localStorage.setItem('access_token', data.access_token);
            localStorage.setItem('refresh_token', data.refresh_token);
          }),
          mergeMap(({ data }) =>
            merge(
              of(loginSuccess({
                username: action$.payload.username,
                accessToken: data.access_token,
                refreshToken: data.refresh_token,
                expiresIn: data.expires_in,
              })),
              from(timer((data.expires_in - 60) * 1000)).pipe(
                mapTo(startTokenSync()),
              ),
            ),
          ),
          catchError((error) => {
            const errorMessage = error.response?.status === 401
              ?
              'Wrong login or password'
              :
              error.response?.data.error_description;
            return of(loginFailed(errorMessage));
          }),
        ),
    ),
  );

export const refreshTokenEpic: EpicFunc = (actions$, state$) => actions$.pipe(
  filter(startTokenSync.match),
  withLatestFrom(state$),
  mergeMap(() =>
    from(API.user.refreshToken(localStorage.refresh_token)).pipe(
      tap(({ data }) => {
        localStorage.setItem('access_token', data.access_token);
        localStorage.setItem('refresh_token', data.refresh_token);
      }),
      mergeMap(({ data }) =>
        merge(
          of(loginSuccess({
            accessToken: data.access_token,
            refreshToken: data.refresh_token,
            expiresIn: data.expires_in,
          })),
          from(timer((data.expires_in - 60) * 1000)).pipe(
            mapTo(startTokenSync()),
          ),
        ),
      ),
      catchError(() => of(logout())),
    ),
  ),
);

export const createUserEpic: EpicFunc = (actions$, _, { location }) =>
  actions$.pipe(
    filter(createUser.match),
    mergeMap((action$) =>
      from(API.user.register(action$.payload)).pipe(
        mergeMap(() =>
          of(createUserSuccess()).pipe(
            tap(() => location.replace('/register?user&success=true')),
          ),
        ),
        catchError((error) =>
          of(createUserFailed(error.response?.data)),
        ),
      ),
    ),
  );

export const logoutEpic: EpicFunc = (actions$) =>
  actions$.pipe(
    filter(logout.match),
    tap(() => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
    }),
    mergeMap(() =>
      of(logoutSuccess()),
    ),
  );

export const sendPasswordRecoveryMailEpic: EpicFunc = (actions$, _, { location }) =>
  actions$.pipe(
    filter(sendPasswordRecoveryMail.match),
    mergeMap((action$) =>
      from(API.user.sendPasswordRecoveryMail(action$.payload)).pipe(
        mergeMap(() =>
          of(sendPasswordRecoveryMailSuccess()).pipe(
            tap(() => location.replace('/password-recovery?success=true')),
          ),
        ),
        catchError((error) =>
          of(sendPasswordRecoveryMailFailure(error.response.message)),
        ),
      ),
    ),
  );

export const setNewPasswordEpic: EpicFunc = (actions$, _, { location }) =>
  actions$.pipe(
    filter(setNewPassword.match),
    mergeMap((action$) =>
      from(API.user.setNewPassword(action$.payload)).pipe(
        mergeMap(() =>
          of(setNewPasswordSuccess()).pipe(
            tap(() => {
              localStorage.removeItem('recoveryEmail');
              return location.replace('/');
            }),
          ),
        ),
        catchError((error) => {
          localStorage.removeItem('recoveryEmail');
          return of(setNewPasswordFailure(error.response.message));
        },
        ),
      ),
    ),
  );
